import { useCallback, useState } from 'react'
import { useQueryClient } from 'react-query'

import {
  UserRegistrationProps,
  useUserRegistrationAPI
} from '../../../api/users/postUserRegistration'
import { QUERY_CACHE_KEYS } from '../../../api/cacheKeys'
import { useUserLoginAPI } from '../../../api/auth/postUserLogin'
import { useNotification } from '../../../atoms/Notification'
import { useGetCurrentUserAPI } from '../../../api/users/getCurrentUser'
import { localStorageKeys } from '../../../constants/localStorageKeys'
import { useRequest } from '../../../hooks/useRequest'

import { IPostUserRegistrationFormValues } from './types'

export const useUserRegistrationModal = (
  emailVerificationCallback: () => void,
  smsVerificationCallback: (phoneNumber: string) => void
) => {
  const requestContext = useRequest()

  const queryClient = useQueryClient()
  const { openSuccessNotification, openErrorNotification } = useNotification()

  const [isOpen, setOpen] = useState(false)
  const [registeredUserPassword, setRegisteredUserPassword] = useState('')

  const { getUser } = useGetCurrentUserAPI()

  const { isLoading: isLoginLoading, userLogin } = useUserLoginAPI({
    onSuccess: ({ data: responseData }) => {
      setRegisteredUserPassword('')
      openSuccessNotification(
        'User Created Successfully',
        'You are now logged in'
      )

      localStorage.setItem(
        localStorageKeys.IFCsession,
        JSON.stringify(responseData)
      )

      requestContext.setSession({
        token: responseData.token,
        userId: responseData.user.id
      })

      getUser()
    },
    onError: () => {
      openErrorNotification('User login failed', 'Please try again later')
    }
  })

  const { isLoading, userRegistration } = useUserRegistrationAPI({
    onSuccess: ({ data: responseData }) => {
      queryClient.invalidateQueries(QUERY_CACHE_KEYS.user(responseData.data.id))

      if (responseData.triggeredEmail) {
        emailVerificationCallback()
      } else {
        smsVerificationCallback(responseData.data.phoneNumber)
      }

      userLogin({
        email: responseData.data.email,
        password: registeredUserPassword
      })

      setOpen(false)
    },
    onError: ({ response }) => {
      switch (response?.data?.message) {
        case 'email already exists':
          openErrorNotification(
            'User registration failed',
            'Emai Is Already Used'
          )
          break

        case 'phone already exists':
          openErrorNotification(
            'User registration failed',
            'Phone Number Is Already Used'
          )
          break

        default:
          openErrorNotification(
            'User registration failed',
            'Please try again later'
          )
          break
      }
    }
  })

  const onOpen = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const onCancel = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const onOpenHandler = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const onOpenUserRegistration = () => {
    onOpenHandler()
  }

  const onSubmit = (userFormValues: IPostUserRegistrationFormValues) => {
    const { name, lastname, phone, email, password } = userFormValues
    const registrationDTO: UserRegistrationProps = {
      firstName: name,
      lastName: lastname,
      email,
      password,
      phoneNumber: phone,
      citizenship: ''
    }
    setRegisteredUserPassword(password)
    setTimeout(() => {
      userRegistration(registrationDTO)
    })
  }

  return {
    onOpenUserRegistration,
    userRegistrationModalProps: {
      isOpen,
      setOpen,
      handleSubmit: onSubmit,
      onCancel,
      onOpen,
      isLoading: isLoading || isLoginLoading
    }
  }
}
