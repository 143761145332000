import { generatePath } from 'react-router-dom'

import {
  ErrorResponse,
  MutationRequestFunction,
  SuccessResponse
} from '../../types'
import { apiRoutes } from '../../apiRoutes'
import { ErrorType } from '../../commonTypes'

import { SmsVerifyResponse } from './types'

export type SmsVerifyProps = {
  code: number
  phone: string
}

export type SmsVerifySuccessResponse = SuccessResponse<SmsVerifyResponse>
export type SmsVerifyErrorResponse = ErrorResponse<ErrorType>

export const smsVerify: MutationRequestFunction<
  SmsVerifySuccessResponse,
  SmsVerifyProps
> =
  ({ request }) =>
  async (dto) =>
    request.post(generatePath(apiRoutes.postSmsVerify), dto)
