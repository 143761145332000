import { FC, PropsWithChildren, useMemo } from 'react'
import { Grid, Stack, StackProps } from '@mui/material'

import { TopNavigationMenu } from '../../features/others/TopNavigationMenu'

// import { TopNavigationMenu } from '../../features/others/TopNavigationMenu'

const containerSx = {
  px: {
    xs: 0,
    lg: 0
  },
  pb: 3
} as StackProps['sx']

export interface PageLayoutProps {
  slotProps?: {
    stack?: StackProps
  }
}

export const PageLayout: FC<PropsWithChildren<PageLayoutProps>> = ({
  children,
  slotProps
}) => {
  const { stackProps } = useMemo(() => {
    const { stack } = slotProps ?? {}

    return {
      stackProps: stack
    }
  }, [slotProps])

  return (
    <Grid
      container
      flexDirection='column'
      alignItems='center'
      sx={{
        backgroundColor: '#0D0D0D',
        maxWidth: '100vw',
        overflowX: 'hidden'
      }}
    >
      <Grid
        item
        sx={{
          position: 'sticky',
          width: '45vw',
          minWidth: '320px',
          top: 30,
          height: 0,
          zIndex: 2
        }}
      >
        <TopNavigationMenu />
      </Grid>

      <Grid item maxWidth='100dvw'>
        <Stack sx={containerSx} gap={3} {...stackProps}>
          {children}
        </Stack>
      </Grid>
    </Grid>
  )
}
