export const formatTimeLeft = (milliseconds: number) => {
  const totalSeconds = Math.floor(milliseconds / 1000)

  // const calculatedSec = (totalSeconds % Math.floor(totalSeconds)) % 60
  // const positiveCalculatedSec = calculatedSec > 0 ? calculatedSec : 0
  // const sec = `${positiveCalculatedSec}`.padStart(2, '0')

  // const calculatedMin = Math.floor(totalSeconds / 60) % 60
  // const positiveCalculatedMin = calculatedMin > 0 ? calculatedMin : 0
  // const min = `${positiveCalculatedMin}`.padStart(2, '0')

  // const calculatedHours = Math.floor(totalSeconds / 60 / 60) % 24
  // const positiveCalculatedHours = calculatedHours > 0 ? calculatedHours : 0
  // const hours = `${positiveCalculatedHours}`.padStart(2, '0')

  // const calculatedDays = Math.floor(totalSeconds / 60 / 60 / 24)
  // const positiveCalculatedDays = calculatedDays > 0 ? calculatedDays : 0
  // const days = `${positiveCalculatedDays}`.padStart(1, '0')
  const sec = `${Math.floor(totalSeconds) % 60}`.padStart(2, '0')
  const min = `${Math.floor(totalSeconds / 60) % 60}`.padStart(2, '0')
  const hours = `${Math.floor(totalSeconds / 60 / 60) % 24}`.padStart(2, '0')
  const days = `${Math.floor(totalSeconds / 60 / 60 / 24)}`.padStart(1, '0')

  return { days, hours, min, sec }
}

export const timeSpentToSeconds = (value: string) => {
  const [hh, mm, ss] = value.split(':').map(Number)

  return hh * 60 * 60 + mm * 60 + ss
}
