import * as Yup from 'yup'

import { IUserLoginFormFields } from './types'

export const loginUserSchema = Yup.object().shape({
  [IUserLoginFormFields.EMAIL]: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  [IUserLoginFormFields.PASSWORD]: Yup.string().required('Password is required')
})
