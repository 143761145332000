import { Box, Stack } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { MainTypography } from '../../atoms/Typography'

import { formatTimeLeft } from './utils'

const TimerSection = ({ value, label }: { value: string; label: string }) => {
  const positiveValue = value.includes('-') ? '0' : value

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <MainTypography style={{ color: 'white', fontSize: '24px' }}>
        {positiveValue}
      </MainTypography>
      <MainTypography style={{ color: 'white', fontSize: '12px' }}>
        {label}
      </MainTypography>
    </Box>
  )
}

export const TimeLeftTimer = ({ eventDate }: { eventDate: string }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: '00',
    hours: '00',
    min: '00',
    sec: '00'
  })

  const getParsedTime = () => {
    setTimeLeft(formatTimeLeft(dayjs(dayjs(eventDate)).diff(new Date())))
  }

  useEffect(() => {
    const interval = setInterval(() => {
      getParsedTime()
    }, 1000)
    return () => clearInterval(interval)
  }, [eventDate])

  return (
    <Box
      sx={{
        padding: '20px 30px',
        backgroundColor: '#000000CC',
        borderRadius: '24px',
        minHeight: '105px',
        minWidth: '206px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <MainTypography style={{ color: '#EB3826', fontSize: '24px' }}>
        EVENT STARTS IN
      </MainTypography>
      <Stack flexDirection='row' alignItems='center' gap={2}>
        <TimerSection value={timeLeft.days} label='DAYS' />
        <TimerSection value={timeLeft.hours} label='HOURS' />
        <TimerSection value={timeLeft.min} label='MINUTES' />
        <TimerSection value={timeLeft.sec} label='SECONDS' />
      </Stack>
    </Box>
  )
}
