import { FC } from 'react'
import { Button, ButtonProps, styled } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const ButtonContainer = styled(Button)(({ theme }) => ({
  minWidth: 0,
  width: 'fit-content',
  padding: theme.spacing(1),
  color: theme.palette.neutral.mediumDark
}))

export const CloseButton: FC<ButtonProps> = (props) => {
  return (
    <ButtonContainer disableElevation variant='text' {...props}>
      <CloseIcon color='inherit' />
    </ButtonContainer>
  )
}
