import { FC, useRef } from 'react'
import { Form, Formik, FormikConfig, FormikProps } from 'formik'
import { Stack } from '@mui/material'

import { TextInputField } from '../../../atoms/TextInputField'
import { ActionModal } from '../../../atoms/Modal'
import { AccentTypography, MainTypography } from '../../../atoms/Typography'
import { FilledButton } from '../../../atoms/FilledButton'
import { OutlinedButton } from '../../../atoms/OutlinedButton'

import { loginUserSchema } from './loginUserValidation'
import {
  IPostUserLoginFormValues,
  IUserLoginFormFields,
  UserLoginModalProps,
  initialValues
} from './types'

export const UserLoginModal: FC<UserLoginModalProps> = ({
  isOpen,
  setOpen,
  handleSubmit,
  isLoading,
  handleForgetPassword,
  handleOpenRegistration
}) => {
  const formRef = useRef<FormikProps<typeof initialValues>>(null)

  const handleSubmitForm: FormikConfig<IPostUserLoginFormValues>['onSubmit'] =
    async (values) => {
      await handleSubmit(values)
    }

  const onForgetPasswordClick = () => {
    const email = formRef.current?.values?.email
    if (email) {
      handleForgetPassword(email)
    } else {
      formRef.current?.setFieldError(
        IUserLoginFormFields.EMAIL,
        'Please Enter Email'
      )
    }
  }

  const onRegisterClick = () => {
    if (handleOpenRegistration) {
      handleOpenRegistration()
    }
  }

  return (
    <ActionModal
      isOpen={isOpen}
      setOpen={() => {
        setOpen(false)
      }}
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange
        validationSchema={loginUserSchema}
        onSubmit={handleSubmitForm}
      >
        <Form>
          <Stack
            sx={{
              width: '100%',
              marginBottom: 3,
              textAlign: 'center'
            }}
          >
            <MainTypography
              sx={{
                color: 'black'
              }}
            >
              Sign In
            </MainTypography>
          </Stack>
          <Stack rowGap={3}>
            <TextInputField name={IUserLoginFormFields.EMAIL} label='Email' />

            <TextInputField
              name={IUserLoginFormFields.PASSWORD}
              label='Password'
              type='password'
            />
            <Stack
              rowGap={2}
              sx={{
                width: '70%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: 'auto'
              }}
            >
              <FilledButton
                disabled={isLoading}
                onClick={() => formRef.current?.submitForm()}
              >
                Sign In
              </FilledButton>
              <OutlinedButton onClick={onForgetPasswordClick}>
                <AccentTypography>Forget Password?</AccentTypography>
              </OutlinedButton>
              <OutlinedButton onClick={onRegisterClick}>
                <AccentTypography>Not a member? Register</AccentTypography>
              </OutlinedButton>
            </Stack>
          </Stack>
        </Form>
      </Formik>
    </ActionModal>
  )
}
