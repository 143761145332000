import { generatePath } from 'react-router-dom'

import {
  ErrorResponse,
  MutationRequestFunction,
  SuccessResponse
} from '../../types'
import { apiRoutes } from '../../apiRoutes'
import { ErrorType } from '../../commonTypes'

import { UserLogOutResponse } from './types'

export type UserLogOutSuccessResponse = SuccessResponse<UserLogOutResponse>
export type UserLogOutErrorResponse = ErrorResponse<ErrorType>

export const userLogOut: MutationRequestFunction<UserLogOutSuccessResponse> =
  ({ request }) =>
  async () =>
    request.post(generatePath(apiRoutes.postUserLogout))
