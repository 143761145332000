import { /* FC, */ forwardRef, useMemo, useState } from 'react'
import {
  Box,
  TextField,
  TextFieldProps,
  Typography,
  formHelperTextClasses,
  inputLabelClasses,
  outlinedInputClasses,
  styled
} from '@mui/material'

export type InputProps = TextFieldProps & {
  counter?: boolean
}

const StyledTextField = styled(TextField)(
  ({ theme: { palette, alert, spacing } }) => ({
    minHeight: '48px',
    [`.${outlinedInputClasses.root}`]: {
      '& fieldset': {
        borderColor: palette.neutral.mediumLight
      },
      ':hover fieldset': {
        borderColor: palette.secondary.main
      },
      [`.${outlinedInputClasses.error}`]: {
        borderColor: alert.high,
        ':hover fieldset': {
          borderColor: alert.high
        }
      },
      [`.${outlinedInputClasses.disabled}`]: {
        borderColor: palette.neutral.lightMedium,
        ':hover fieldset': {
          borderColor: palette.neutral.lightMedium
        }
      },
      [`.${outlinedInputClasses.focused} fieldset`]: {
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: palette.primary.main
      },
      [`.${outlinedInputClasses.focused}.${outlinedInputClasses.error} fieldset`]:
        {
          borderColor: alert.high
        },
      [`.${outlinedInputClasses.input}`]: {
        color: palette.neutral.extraDark,
        padding: '12px 16px'
      }
    },
    [`.${inputLabelClasses.root}:not(.${inputLabelClasses.shrink})`]: {
      transform: `translate(${spacing(2)}, ${spacing(1.5)}) scale(1)`
    },
    [`.${formHelperTextClasses.root}`]: {
      position: 'absolute',
      bottom: '-16px',
      left: '16px',
      height: '16px',
      margin: 0
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none'
    },
    '& input[type=number]': {
      MozAppearance: 'textfield'
    }
  })
)

export const Input = forwardRef<HTMLDivElement, InputProps>((props, ref) => {
  const { counter = false, onFocus, onBlur, helperText, ...fieldProps } = props
  const [counterVisible, setCounterVisible] = useState<boolean>(false)
  const trimmedHelperText = useMemo(
    () => (typeof helperText === 'string' ? helperText?.trim() : helperText),
    [helperText]
  )
  const inputHelperProps = useMemo(
    () => ({
      disabled: fieldProps.disabled,
      error: fieldProps.error,
      sx: {
        width: '92%'
      },
      ...fieldProps.FormHelperTextProps
    }),
    [fieldProps]
  )

  return (
    <StyledTextField
      ref={ref}
      fullWidth
      variant='outlined'
      {...fieldProps}
      onFocus={(event) => {
        setCounterVisible(true)
        onFocus && onFocus(event)
      }}
      onBlur={(event) => {
        setCounterVisible(false)
        onBlur && onBlur(event)
      }}
      helperText={
        <Box
          component='div'
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Typography variant='bodySmall'>{trimmedHelperText}</Typography>
          {counterVisible && counter && (
            <Typography variant='bodySmall'>
              {`${((props.value as string) || '').length} / ${
                fieldProps?.inputProps?.maxLength
              }`}
            </Typography>
          )}
        </Box>
      }
      InputProps={{
        ...fieldProps.InputProps,
        endAdornment: fieldProps.InputProps?.endAdornment,
        startAdornment: fieldProps.InputProps?.startAdornment
      }}
      FormHelperTextProps={inputHelperProps}
    />
  )
})
