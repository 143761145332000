import { FC } from 'react'
import { Box } from '@mui/material'

import { ActionModal } from '../../../atoms/Modal'

import { StreamModalProps } from './types'

export const StreamModal: FC<StreamModalProps> = ({ isOpen, setOpen }) => {
  return (
    <ActionModal
      isOpen={isOpen}
      setOpen={() => {
        setOpen(false)
      }}
      sx={{
        width: '80%',
        maxWidth: '85vw',
        maxHeight: '90vh',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{ position: 'relative', paddingTop: '56.25%', minWidth: '50vw' }}
      >
        <iframe
          title='Kinescope video player'
          src='https://kinescope.io/embed/0chVg3x73LTvFjdWjVKL7h'
          allow='autoplay; fullscreen; picture-in-picture; encrypted-media; gyroscope; accelerometer; clipboard-write;'
          frameBorder='0'
          allowFullScreen
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0
          }}
        />
      </Box>
    </ActionModal>
  )
}
