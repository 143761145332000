import { FC } from 'react'
import { Button, ButtonProps, buttonClasses, styled } from '@mui/material'

export type FilledButtonProps = Omit<ButtonProps, 'variant'>

const DEFAULT_BUTTON_SIZE = 48

const StyledButton = styled(Button)(({ theme }) => ({
  height: DEFAULT_BUTTON_SIZE,
  backgroundColor: '#EB3826' /* theme.palette.primary.main */,
  ...theme.typography.labelLarge,
  textTransform: 'none',
  [`&.${buttonClasses.disabled}`]: {
    backgroundColor: theme.palette.neutral.light,
    color: theme.palette.neutral.medium
  },
  [`&.${buttonClasses.focusVisible}`]: {
    backgroundColor: '#EB3826' /* theme.palette.primary.dark */
  },
  [`&:hover`]: {
    backgroundColor: '#b02619' /* theme.palette.primary.dark */
  }
}))

export const FilledButton: FC<FilledButtonProps> = ({ children, ...props }) => {
  return (
    <StyledButton variant='contained' {...props}>
      {children}
    </StyledButton>
  )
}
