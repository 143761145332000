import { useEffect, useRef } from 'react'

import { useRefreshTokenAPI } from '../api/security/refreshToken'
import { useRequest } from '../hooks/useRequest'
import { localStorageKeys } from '../constants/localStorageKeys'

export const RefreshTokenController = () => {
  const tokenTimerId = useRef<number>()
  const { sessionResponse, logout } = useRequest()
  const { refreshToken } = useRefreshTokenAPI({
    onError: () => {
      logout()
    }
  })

  useEffect(() => {
    if (sessionResponse) {
      const delay = 3 * 60 * 1000
      const sessionToken = JSON.parse(
        localStorage.getItem(localStorageKeys.IFCsession) || ''
      )?.token?.sessionToken
      tokenTimerId.current = setTimeout(() => {
        if (sessionToken) {
          refreshToken({ sessionToken })
        }
      }, delay) as unknown as number
    } else {
      clearTimeout(tokenTimerId.current)
    }

    return () => {
      clearTimeout(tokenTimerId.current)
    }
  }, [sessionResponse])

  return null
}
