import * as Yup from 'yup'

import { IUserRegistrationFormFields } from './types'

//                     '^+?(d{1,3})?[-.s]?((?d{1,4})?)?[-.s]?d{1,4}[-.s]?d{1,4}[-.s]?d{1,9}$'
export const registerUserSchema = Yup.object().shape({
  [IUserRegistrationFormFields.NAME]: Yup.string().required('Name is required'),
  [IUserRegistrationFormFields.LASTNAME]: Yup.string().required(
    'Last Name is required'
  ),
  [IUserRegistrationFormFields.PHONE]: Yup.string()
    .matches(
      /^\+?(\d{1,3})?[-.\s]?(\d{1,4})?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
      'Phone number is not valid'
    )
    .required('Phone number is required'),
  [IUserRegistrationFormFields.EMAIL]: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  [IUserRegistrationFormFields.PASSWORD]: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  [IUserRegistrationFormFields.CONFIRM_PASSWORD]: Yup.string()
    .oneOf(
      [Yup.ref(IUserRegistrationFormFields.PASSWORD)],
      'Passwords must match'
    )
    .required('Confirm Password is required')
})
