import { FC } from 'react'
import { Stack } from '@mui/material'

import { ActionModal } from '../../../atoms/Modal'
import { MainTypography } from '../../../atoms/Typography'
import { FilledButton } from '../../../atoms/FilledButton'

import { PaymentModalProps } from './types'

export const PaymentModal: FC<PaymentModalProps> = ({
  isOpen,
  setOpen,
  handleSubmit,
  isLoading
}) => {
  const handleSubmitForm = async () => {
    await handleSubmit()
  }

  // const emailAddress = useMemo(() => {
  //   return userInfo?.email || ''
  // }, [userInfo])

  return (
    <ActionModal
      isOpen={isOpen}
      setOpen={() => {
        setOpen(false)
      }}
    >
      <Stack
        sx={{
          width: '100%',
          marginBottom: 3,
          textAlign: 'center'
        }}
      >
        <MainTypography
          sx={{
            color: 'black'
          }}
        >
          Payment
        </MainTypography>
      </Stack>
      <Stack rowGap={3}>
        <MainTypography
          sx={{
            color: 'black',
            fontSize: '18px',
            textAlign: 'center'
          }}
        >
          Please Follow The Instructions Below
        </MainTypography>
        <MainTypography
          sx={{
            color: 'black',
            fontSize: '16px',
            textAlign: 'center'
          }}
        >
          <span
            style={{
              color: 'red',
              paddingRight: '5px'
            }}
          >
            NOTE:
          </span>
          Payment Can Not Be Done By
          <span style={{ color: 'orange' }}> VISA</span> Cards
        </MainTypography>
        {/* <MainTypography
          sx={{
            color: 'black',
            textAlign: 'center'
          }}
        >
          {emailAddress}
        </MainTypography> */}

        <Stack
          rowGap={2}
          sx={{
            width: '70%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 'auto'
          }}
        >
          <FilledButton disabled={isLoading} onClick={handleSubmitForm}>
            Buy Stream
          </FilledButton>
        </Stack>
      </Stack>
    </ActionModal>
  )
}
