import { OptionsObject, useSnackbar } from 'notistack'
import { useCallback } from 'react'

export const useNotification = () => {
  const { enqueueSnackbar } = useSnackbar()

  const openNotification = useCallback(
    (
      title: string,
      message: string = '',
      severity: 'success' | 'error' = 'success'
    ) => {
      enqueueSnackbar(message, {
        variant: severity,
        title
      } as OptionsObject)
    },
    [enqueueSnackbar]
  )

  const openSuccessNotification = useCallback(
    (title: string, message?: string) => {
      openNotification(title, message, 'success')
    },
    [openNotification]
  )

  const openErrorNotification = useCallback(
    (title: string, message?: string) => {
      openNotification(title, message, 'error')
    },
    [openNotification]
  )

  return { openNotification, openSuccessNotification, openErrorNotification }
}
