import { Box, Grid, Stack } from '@mui/material'

export const EventCardsListWidget = () => {
  const fightersCard = [
    {
      cardType: (
        <img
          src='/FighterCards/mainCard.png'
          alt='Main Card'
          style={{
            width: '270px',
            height: '60px'
          }}
        />
      ),
      fighters: [
        <img src='/FighterCards/kartvela.png' alt='' />,
        <img src='/FighterCards/tifiyev.png' alt='' />
      ]
    },
    {
      cardType: (
        <img
          src='/FighterCards/prelims.png'
          alt='Prelims Card'
          style={{
            width: '270px',
            height: '60px'
          }}
        />
      ),
      fighters: [
        <img src='/FighterCards/mamporia.png' alt='' />,
        <img src='/FighterCards/gogola.png' alt='' />,
        <img src='/FighterCards/vefxia.png' alt='' />,
        <img src='/FighterCards/gediev.png' alt='' />,
        <img src='/FighterCards/gogia.png' alt='' />,
        <img src='/FighterCards/sultanishvili rustamov.png' alt='' />
      ]
    },
    {
      cardType: (
        <img
          src='/FighterCards/earlyPrelims.png'
          alt='Early Prelims Card'
          style={{
            width: '100%',
            height: '60px'
          }}
        />
      ),
      fighters: [
        <img src='/FighterCards/faroi karayel.png' alt='' />,
        <img src='/FighterCards/faroi kesenek.png' alt='' />
      ]
    }
  ]

  return (
    <Stack
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
      rowGap={3}
      columnGap={2}
    >
      {fightersCard.map(({ cardType, fighters }, cardInd) => (
        <Stack
          key={cardInd}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 3,
            margin: '0px 10px'
          }}
        >
          <Box
            sx={{
              width: {
                xs: '270px',
                md: 'fit-content'
              }
            }}
          >
            {cardType}
          </Box>
          <Grid container justifyContent='center' columnGap={4} rowGap={2}>
            {fighters.map((pic, ind) => (
              <Grid
                item
                key={ind}
                lg={3}
                md={5}
                xs={12}
                sm={12}
                sx={{
                  margin: '0px 22px'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {pic}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Stack>
      ))}
    </Stack>
  )
}
