export const IFCLogoSmall = () => (
  <svg
    width='102'
    height='88'
    viewBox='0 0 102 88'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M24.1238 11.4531L31.0401 4.53679C32.5536 3.02335 34.607 2.17231 36.7483 2.17231H64.4208C66.562 2.17231 68.6155 3.02335 70.1289 4.53679L77.0452 11.4531H79.7971L70.9353 2.59135C69.4219 1.07791 67.3684 0.226868 65.2272 0.226868H35.9433C33.8021 0.226868 31.7486 1.07791 30.2352 2.59135L21.3734 11.4531H24.1253H24.1238Z'
      fill='black'
    />
    <path
      d='M24.1238 76.5469L31.0401 83.4632C32.5536 84.9766 34.607 85.8277 36.7483 85.8277H64.4208C66.562 85.8277 68.6155 84.9766 70.1289 83.4632L77.0452 76.5469H79.7971L70.9353 85.4086C69.4219 86.9221 67.3684 87.7731 65.2272 87.7731H35.9433C33.8021 87.7731 31.7486 86.9221 30.2352 85.4086L21.3734 76.5469H24.1253H24.1238Z'
      fill='black'
    />
    <path
      d='M0.466006 67.4677L0.496245 60.2619C0.502005 60.2345 0.514965 60.2216 0.535125 60.2216H1.78649C1.83401 60.2216 1.85705 60.2417 1.85561 60.2821L1.82536 67.4878C1.8196 67.5137 1.80665 67.5281 1.78649 67.5281H0.535125C0.487605 67.5281 0.464566 67.508 0.466006 67.4677Z'
      fill='black'
    />
    <path
      d='M57.5923 79.8085L57.6226 72.6027C57.6283 72.5753 57.6413 72.5624 57.6615 72.5624H58.9128C58.9603 72.5624 58.9834 72.5825 58.9819 72.6229L58.9517 79.8286C58.9459 79.8545 58.933 79.8689 58.9128 79.8689H57.6615C57.6139 79.8689 57.5909 79.8488 57.5923 79.8085Z'
      fill='black'
    />
    <path
      d='M5.19935 60.2619C5.20511 60.2345 5.21808 60.2216 5.23824 60.2216H6.41903C6.46655 60.2216 6.4896 60.2417 6.48672 60.2821L6.45647 67.4878C6.45071 67.5137 6.43775 67.5281 6.41759 67.5281H4.84367C4.80911 67.5281 4.78751 67.5109 4.77743 67.4777L3.80831 62.8856L3.79247 67.4878C3.78671 67.5137 3.77375 67.5281 3.75359 67.5281H2.56272C2.5152 67.5281 2.49215 67.508 2.49359 67.4677L2.52383 60.2619C2.52959 60.2345 2.54256 60.2216 2.56272 60.2216H4.15679C4.18991 60.2216 4.21296 60.2389 4.22304 60.272L5.17631 64.9448L5.19935 60.2619Z'
      fill='black'
    />
    <path
      d='M6.83811 61.6342C6.79059 61.6342 6.76754 61.6141 6.76898 61.5737L6.78339 60.2619C6.78915 60.2345 6.80211 60.2216 6.82227 60.2216H9.93123C9.97875 60.2216 10.0018 60.2417 9.99891 60.2821L9.9845 61.5939C9.97874 61.6213 9.96578 61.6342 9.94562 61.6342H9.05714L9.03843 67.4878C9.03267 67.5152 9.01971 67.5281 8.99955 67.5281H7.74818C7.70066 67.5281 7.67763 67.508 7.67907 67.4677L7.69346 61.6342H6.83523H6.83811Z'
      fill='black'
    />
    <path
      d='M10.3344 60.2216H12.9077C12.9552 60.2216 12.9783 60.2418 12.9768 60.2821L12.9624 61.5939C12.9567 61.6213 12.9437 61.6342 12.9235 61.6342H11.6419L11.6463 63.0066H12.8371C12.8847 63.0066 12.9063 63.0267 12.9048 63.067L12.8904 64.3789C12.8847 64.4062 12.8717 64.4192 12.8515 64.4192H11.6304V66.1141H12.8803C12.9279 66.1141 12.9509 66.1342 12.9495 66.1746L12.9451 67.4864C12.9394 67.5137 12.9264 67.5267 12.9063 67.5267H10.333C10.2855 67.5267 10.2624 67.5066 10.2639 67.4662L10.2941 60.2605C10.2999 60.2331 10.3128 60.2202 10.333 60.2202'
      fill='black'
    />
    <path
      d='M42.6033 72.5624H45.1766C45.2241 72.5624 45.2472 72.5825 45.2457 72.6229L45.2313 73.9347C45.2256 73.9621 45.2126 73.975 45.1925 73.975H43.9109L43.9152 75.3473H45.1061C45.1536 75.3473 45.1752 75.3675 45.1737 75.4078L45.1593 76.7197C45.1536 76.747 45.1406 76.76 45.1205 76.76H43.8993V78.4549H45.1493C45.1968 78.4549 45.2198 78.475 45.2184 78.5153L45.2141 79.8272C45.2083 79.8545 45.1953 79.8675 45.1752 79.8675H42.6019C42.5544 79.8675 42.5313 79.8473 42.5328 79.807L42.563 72.6013C42.5688 72.5739 42.5817 72.5609 42.6019 72.5609'
      fill='black'
    />
    <path
      d='M16.306 67.4086C16.175 67.4432 16.0958 67.449 15.9705 67.4691C15.8452 67.4893 15.717 67.5008 15.5874 67.5037C15.4578 67.5066 15.3311 67.5094 15.2102 67.5094H15.0287C14.8674 67.5094 14.7004 67.4922 14.529 67.459C14.3577 67.4259 14.1964 67.3698 14.0452 67.292C13.894 67.2142 13.7615 67.1149 13.6492 66.9939C13.5354 66.873 13.4534 66.7246 13.4015 66.5504C13.3713 66.4496 13.3554 66.3286 13.354 66.1875L13.3785 61.8171C13.3713 61.2051 13.4937 60.763 13.7471 60.4894C13.9991 60.2173 14.4138 60.0805 14.9927 60.0805C15.0662 60.0805 15.1367 60.0819 15.2015 60.0862C15.2663 60.0891 15.3268 60.0949 15.383 60.1006C15.4305 60.1006 15.4535 60.1237 15.455 60.1712L15.3902 61.5838C15.3844 61.6112 15.3714 61.6242 15.3513 61.6242C15.3311 61.6242 15.311 61.6112 15.2894 61.5838C15.2519 61.5234 15.1756 61.4931 15.0604 61.4931C14.8314 61.4931 14.7249 61.6112 14.7407 61.8459L14.7206 65.9226C14.7191 65.9629 14.7206 66.0003 14.7234 66.0334C14.7263 66.0666 14.7306 66.091 14.735 66.104C14.7652 66.2048 14.8574 66.2552 15.0114 66.2552C15.1252 66.2552 15.2058 66.2293 15.2505 66.1746C15.2951 66.1213 15.321 66.0262 15.3282 65.8923L15.3801 63.2787C15.3801 63.2586 15.3945 63.2485 15.4218 63.2485H16.6228C16.6703 63.2485 16.6934 63.2686 16.6919 63.309L16.6746 67.1941C16.6689 67.2214 16.666 67.2646 16.5638 67.3222'
      fill='black'
    />
    <path
      d='M41.6703 79.8805C41.5392 79.915 41.46 79.9208 41.3347 79.9409C41.2095 79.9611 41.0813 79.9726 40.9517 79.9755C40.8221 79.9784 40.6954 79.9813 40.5744 79.9813H40.393C40.2317 79.9813 40.0647 79.964 39.8933 79.9309C39.7219 79.8977 39.5607 79.8416 39.4095 79.7638C39.2583 79.6861 39.1258 79.5867 39.0135 79.4657C38.8997 79.3448 38.8176 79.1965 38.7658 79.0222C38.7355 78.9214 38.7197 78.8005 38.7183 78.6593L38.7427 74.2889C38.7355 73.6769 38.8579 73.2349 39.1114 72.9613C39.3634 72.6891 39.7781 72.5523 40.357 72.5523C40.4304 72.5523 40.501 72.5537 40.5658 72.5581C40.6306 72.5609 40.6911 72.5667 40.7472 72.5725C40.7947 72.5725 40.8178 72.5955 40.8192 72.643L40.7544 74.0557C40.7487 74.083 40.7357 74.096 40.7155 74.096C40.6954 74.096 40.6752 74.083 40.6536 74.0557C40.6162 73.9952 40.5399 73.9649 40.4247 73.9649C40.1957 73.9649 40.0891 74.083 40.105 74.3177L40.0848 78.3944C40.0834 78.4347 40.0848 78.4721 40.0877 78.5053C40.0906 78.5384 40.0949 78.5629 40.0992 78.5758C40.1295 78.6766 40.2216 78.727 40.3757 78.727C40.4895 78.727 40.5701 78.7011 40.6147 78.6464C40.6594 78.5931 40.6853 78.4981 40.6925 78.3641L40.7443 75.7505C40.7443 75.7304 40.7587 75.7203 40.7861 75.7203H41.9871C42.0346 75.7203 42.0576 75.7405 42.0562 75.7808L42.0389 79.6659C42.0331 79.6933 42.0303 79.7365 41.928 79.7941'
      fill='black'
    />
    <path
      d='M56.6981 79.8805C56.5671 79.915 56.4879 79.9208 56.3626 79.9409C56.2373 79.9611 56.1091 79.9726 55.9795 79.9755C55.8499 79.9784 55.7232 79.9813 55.6023 79.9813H55.4208C55.2595 79.9813 55.0925 79.964 54.9211 79.9309C54.7498 79.8977 54.5885 79.8416 54.4373 79.7638C54.2861 79.6861 54.1536 79.5867 54.0413 79.4657C53.9275 79.3448 53.8455 79.1965 53.7936 79.0222C53.7634 78.9214 53.7475 78.8005 53.7461 78.6593L53.7706 74.2889C53.7634 73.6769 53.8858 73.2349 54.1392 72.9613C54.3912 72.6891 54.8059 72.5523 55.3848 72.5523C55.4583 72.5523 55.5288 72.5537 55.5936 72.5581C55.6584 72.5609 55.7189 72.5667 55.775 72.5725C55.8226 72.5725 55.8456 72.5955 55.847 72.643L55.7823 74.0557C55.7765 74.083 55.7635 74.096 55.7434 74.096C55.7232 74.096 55.7031 74.083 55.6815 74.0557C55.644 73.9952 55.5677 73.9649 55.4525 73.9649C55.2235 73.9649 55.117 74.083 55.1328 74.3177L55.1127 78.3944C55.1112 78.4347 55.1127 78.4721 55.1155 78.5053C55.1184 78.5384 55.1227 78.5629 55.127 78.5758C55.1573 78.6766 55.2495 78.727 55.4035 78.727C55.5173 78.727 55.5979 78.7011 55.6426 78.6464C55.6872 78.5931 55.7131 78.4981 55.7203 78.3641L55.7722 75.7505C55.7722 75.7304 55.7866 75.7203 55.8139 75.7203H57.0149C57.0624 75.7203 57.0855 75.7405 57.084 75.7808L57.0667 79.6659C57.061 79.6933 57.0581 79.7365 56.9559 79.7941'
      fill='black'
    />
    <path
      d='M18.659 67.4878C18.6518 67.5152 18.6388 67.5281 18.6187 67.5281H17.3673C17.3198 67.5281 17.2982 67.508 17.3011 67.4677L17.2751 60.2619C17.2809 60.2345 17.2953 60.2216 17.3155 60.2216H18.9196C19.3631 60.2216 19.7174 60.2849 19.9795 60.4131C20.2415 60.5413 20.4187 60.776 20.5094 61.1201C20.5339 61.2137 20.5526 61.3131 20.5627 61.4182C20.5727 61.5219 20.5785 61.6357 20.5756 61.7566L20.5799 62.6149C20.5799 62.7157 20.5699 62.8222 20.5526 62.9331C20.5353 63.044 20.5036 63.1505 20.4604 63.2513C20.4172 63.3521 20.3553 63.4429 20.2747 63.5235C20.1955 63.6041 20.0961 63.6646 19.9795 63.7049C20.2617 63.8057 20.4503 64.0347 20.5439 64.3904C20.5958 64.5862 20.6203 64.8339 20.6203 65.1377L20.6303 66.7016C20.6275 66.8974 20.6303 67.0429 20.6347 67.1408C20.6404 67.2387 20.6505 67.3136 20.6649 67.3683C20.6707 67.3885 20.6851 67.4187 20.7095 67.459C20.7355 67.5065 20.7254 67.5296 20.6779 67.5296H19.4265C19.3991 67.5296 19.3804 67.5195 19.3675 67.4993C19.3473 67.472 19.3329 67.4461 19.3257 67.4187C19.3171 67.3856 19.3084 67.351 19.2998 67.3179C19.2926 67.2905 19.2883 67.2675 19.2854 67.2473C19.2839 67.2272 19.2854 67.1998 19.2897 67.1667L19.2825 67.1768L19.2609 64.7245C19.2609 64.6971 19.2537 64.6611 19.2422 64.6136C19.2119 64.4998 19.1241 64.435 18.9801 64.4221C18.9398 64.4221 18.9211 64.4019 18.9239 64.3616L18.8591 63.0497C18.8519 63.0224 18.8649 63.0094 18.8995 63.0094C19.0132 63.0094 19.0953 62.9677 19.1443 62.8827C19.1932 62.7992 19.2177 62.6091 19.2206 62.3125C19.2206 62.0835 19.2076 61.9193 19.1803 61.8185C19.1486 61.6976 19.0622 61.6371 18.9196 61.6371H18.6475L18.6604 67.4907L18.659 67.4878Z'
      fill='black'
    />
    <path
      d='M51.1194 79.8661C51.1122 79.8934 51.0993 79.9064 51.0791 79.9064H49.8278C49.7802 79.9064 49.7586 79.8862 49.7615 79.8459L49.7356 72.6401C49.7414 72.6128 49.7558 72.5998 49.7759 72.5998H51.3801C51.8236 72.5998 52.1778 72.6632 52.4399 72.7913C52.702 72.9195 52.8791 73.1542 52.9698 73.4984C52.9943 73.592 53.013 73.6913 53.0231 73.7965C53.0332 73.9001 53.039 74.0139 53.0361 74.1349L53.0404 74.9931C53.0404 75.0939 53.0303 75.2005 53.013 75.3113C52.9958 75.4222 52.9641 75.5288 52.9209 75.6296C52.8777 75.7304 52.8158 75.8211 52.7351 75.9017C52.6559 75.9824 52.5566 76.0429 52.4399 76.0832C52.7222 76.184 52.9108 76.4129 53.0044 76.7686C53.0562 76.9645 53.0807 77.2121 53.0807 77.516L53.0908 79.0798C53.0879 79.2757 53.0908 79.4211 53.0951 79.519C53.1009 79.6169 53.111 79.6918 53.1254 79.7465C53.1311 79.7667 53.1455 79.7969 53.17 79.8373C53.1959 79.8848 53.1858 79.9078 53.1383 79.9078H51.887C51.8596 79.9078 51.8409 79.8977 51.8279 79.8776C51.8078 79.8502 51.7934 79.8243 51.7862 79.7969C51.7775 79.7638 51.7689 79.7293 51.7602 79.6961C51.753 79.6688 51.7487 79.6457 51.7458 79.6256C51.7444 79.6054 51.7458 79.5781 51.7502 79.5449L51.743 79.555L51.7214 77.1027C51.7214 77.0753 51.7142 77.0393 51.7026 76.9918C51.6724 76.8781 51.5846 76.8133 51.4406 76.8003C51.4002 76.8003 51.3815 76.7801 51.3844 76.7398L51.3196 75.428C51.3124 75.4006 51.3254 75.3877 51.3599 75.3877C51.4737 75.3877 51.5558 75.3459 51.6047 75.2609C51.6537 75.1774 51.6782 74.9873 51.681 74.6907C51.681 74.4617 51.6681 74.2976 51.6407 74.1968C51.609 74.0758 51.5226 74.0153 51.3801 74.0153H51.1079L51.1209 79.8689L51.1194 79.8661Z'
      fill='black'
    />
    <path
      d='M20.7729 67.508C20.7556 67.495 20.7498 67.4777 20.7585 67.4576L21.8284 60.2518C21.8428 60.2317 21.8558 60.2216 21.8702 60.2216H23.5348C23.5679 60.2216 23.591 60.2417 23.6039 60.2821L24.6134 67.4878C24.6206 67.5137 24.609 67.5281 24.5745 67.5281H23.2828C23.2353 67.5281 23.2122 67.508 23.2137 67.4677L23.0769 66.2566H22.3094L22.1841 67.4979C22.1769 67.5181 22.1625 67.5281 22.1423 67.5281H20.8305C20.8103 67.5281 20.7916 67.5209 20.7743 67.508M22.7198 62.0691L22.4534 64.844H22.9372L22.7183 62.0691H22.7198Z'
      fill='black'
    />
    <path
      d='M26.8037 60.2216H29.377C29.4245 60.2216 29.4475 60.2417 29.4461 60.2821L29.4317 61.5939C29.4259 61.6213 29.413 61.6342 29.3928 61.6342H28.1112L28.1155 63.0065H29.3064C29.3539 63.0065 29.3755 63.0267 29.3755 63.067L29.3611 64.3789C29.3554 64.4062 29.3424 64.4192 29.3222 64.4192H28.1011L28.0939 67.4878C28.0882 67.5152 28.0752 67.5281 28.055 67.5281H26.8037C26.7562 67.5281 26.7331 67.508 26.7346 67.4677L26.7648 60.2619C26.7706 60.2345 26.7835 60.2216 26.8037 60.2216Z'
      fill='black'
    />
    <path
      d='M29.9443 67.4677L29.9745 60.2619C29.9803 60.2345 29.9932 60.2216 30.0134 60.2216H31.2648C31.3123 60.2216 31.3353 60.2417 31.3339 60.2821L31.3036 67.4878C31.2979 67.5137 31.2849 67.5281 31.2648 67.5281H30.0134C29.9659 67.5281 29.9428 67.508 29.9443 67.4677Z'
      fill='black'
    />
    <path
      d='M35.9073 60.2216H37.1587C37.2062 60.2216 37.2292 60.2417 37.2278 60.2821L37.2192 63.0065H37.834L37.847 60.2619C37.8528 60.2345 37.8657 60.2216 37.8859 60.2216H39.1372C39.1848 60.2216 39.2078 60.2417 39.2064 60.2821L39.1761 67.4878C39.1704 67.5152 39.1574 67.5281 39.1372 67.5281H37.8859C37.8384 67.5281 37.8153 67.508 37.8168 67.4677L37.8196 64.4206H37.2048L37.1976 67.4893C37.1918 67.5166 37.1788 67.5296 37.1587 67.5296H35.9073C35.8598 67.5296 35.8368 67.5094 35.8382 67.4691L35.8684 60.2633C35.8742 60.236 35.8872 60.223 35.9073 60.223'
      fill='black'
    />
    <path
      d='M39.5549 61.6342C39.5074 61.6342 39.4843 61.6141 39.4858 61.5737L39.5002 60.2619C39.5059 60.2345 39.5189 60.2216 39.5391 60.2216H42.6466C42.6941 60.2216 42.7171 60.2417 42.7157 60.2821L42.7013 61.5939C42.6955 61.6213 42.6826 61.6342 42.6624 61.6342H41.7739L41.7552 67.4878C41.7495 67.5152 41.7365 67.5281 41.7163 67.5281H40.465C40.4175 67.5281 40.3944 67.508 40.3959 67.4677L40.4103 61.6342H39.552H39.5549Z'
      fill='black'
    />
    <path
      d='M43.0224 67.4677L43.0526 60.2619C43.0584 60.2345 43.0714 60.2216 43.0915 60.2216H44.3429C44.3904 60.2216 44.4134 60.2417 44.412 60.2821L44.3818 67.4878C44.376 67.5137 44.363 67.5281 44.3429 67.5281H43.0915C43.044 67.5281 43.021 67.508 43.0224 67.4677Z'
      fill='black'
    />
    <path
      d='M47.7557 60.2619C47.7614 60.2345 47.7744 60.2216 47.7946 60.2216H48.9754C49.0229 60.2216 49.0445 60.2417 49.0445 60.2821L49.0142 67.4878C49.0085 67.5137 48.9955 67.5281 48.9754 67.5281H47.4014C47.3683 67.5281 47.3453 67.5109 47.3366 67.4777L46.3675 62.8856L46.3517 67.4878C46.3459 67.5137 46.333 67.5281 46.3128 67.5281H45.1219C45.0744 67.5281 45.0514 67.508 45.0528 67.4677L45.083 60.2619C45.0888 60.2345 45.1018 60.2216 45.1219 60.2216H46.716C46.7491 60.2216 46.7722 60.2389 46.7822 60.272L47.7355 64.9448L47.7586 60.2619H47.7557Z'
      fill='black'
    />
    <path
      d='M55.6598 60.4894C55.9118 60.2173 56.3265 60.0805 56.9054 60.0805C57.3431 60.0805 57.6859 60.1568 57.9364 60.308C58.187 60.4592 58.3612 60.6997 58.4577 61.0294C58.5211 61.2454 58.5542 61.5133 58.5556 61.8373V62.8568C58.5499 62.8842 58.5369 62.8971 58.5167 62.8971H57.2654C57.2179 62.8971 57.1948 62.877 57.1963 62.8366L57.1891 61.7566C57.1862 61.7034 57.1847 61.6731 57.1819 61.6659C57.166 61.6126 57.1329 61.5694 57.0839 61.5392C57.035 61.509 56.9831 61.4931 56.9284 61.4931C56.8348 61.4931 56.7614 61.5248 56.7095 61.5896C56.6577 61.653 56.6318 61.7768 56.6318 61.9582L56.6159 65.8131C56.6217 65.947 56.6332 66.0421 56.6491 66.0954C56.6807 66.2034 56.7571 66.2566 56.878 66.2566C56.9457 66.2566 57.0076 66.2408 57.0652 66.2062C57.0811 66.1933 57.0998 66.1904 57.1199 66.2005C57.1401 66.2106 57.1502 66.2293 57.1516 66.2552L57.1833 67.6174C57.1775 67.6434 57.1646 67.6578 57.1444 67.6578C57.1243 67.6578 57.1041 67.6592 57.0854 67.6635C57.0667 67.6664 57.0465 67.6693 57.0263 67.6693H56.9054C56.4676 67.6693 56.1263 67.5958 55.8801 67.4475C55.6339 67.2992 55.4639 67.0645 55.3675 66.7405C55.2998 66.5115 55.2681 66.2365 55.2739 65.9125L55.2883 61.8157C55.2811 61.2037 55.4035 60.7616 55.6569 60.488'
      fill='black'
    />
    <path
      d='M59.0668 60.2216H60.3182C60.3657 60.2216 60.3887 60.2417 60.3873 60.2821L60.3787 63.0065H60.9935L61.0065 60.2619C61.0123 60.2345 61.0252 60.2216 61.0454 60.2216H62.2967C62.3443 60.2216 62.3673 60.2417 62.3659 60.2821L62.3356 67.4878C62.3299 67.5152 62.3169 67.5281 62.2967 67.5281H61.0454C60.9979 67.5281 60.9748 67.508 60.9763 67.4677L60.9791 64.4206H60.3643L60.3571 67.4893C60.3513 67.5166 60.3383 67.5296 60.3182 67.5296H59.0668C59.0193 67.5296 58.9963 67.5094 58.9991 67.4691L59.0294 60.2633C59.0351 60.236 59.0481 60.223 59.0683 60.223'
      fill='black'
    />
    <path
      d='M62.6439 67.508C62.6266 67.495 62.6208 67.4777 62.6295 67.4576L63.6994 60.2518C63.7138 60.2317 63.7267 60.2216 63.7411 60.2216H65.4058C65.4389 60.2216 65.4619 60.2417 65.4749 60.2821L66.4843 67.4878C66.493 67.5137 66.48 67.5281 66.4455 67.5281H65.1538C65.1063 67.5281 65.0832 67.508 65.0861 67.4677L64.9479 66.2566H64.1803L64.0551 67.4979C64.0479 67.5181 64.0335 67.5281 64.0133 67.5281H62.7015C62.6813 67.5281 62.6626 67.5209 62.6453 67.508M64.5907 62.0691L64.3243 64.844H64.8082L64.5893 62.0691H64.5907Z'
      fill='black'
    />
    <path
      d='M59.4384 79.7926C59.4211 79.7797 59.4154 79.7624 59.424 79.7422L60.4939 72.5365C60.5083 72.5163 60.5213 72.5062 60.5357 72.5062H62.2003C62.2335 72.5062 62.2565 72.5264 62.2695 72.5667L63.2789 79.7725C63.2875 79.7984 63.2746 79.8128 63.24 79.8128H61.9483C61.9008 79.8128 61.8778 79.7926 61.8807 79.7523L61.7424 78.5413H60.9749L60.8496 79.7825C60.8424 79.8027 60.828 79.8128 60.8079 79.8128H59.496C59.4759 79.8128 59.4571 79.8056 59.4399 79.7926M61.3867 74.3523L61.1203 77.1272H61.6042L61.3853 74.3523H61.3867Z'
      fill='black'
    />
    <path
      d='M69.9748 60.2518C69.9748 60.2317 69.9892 60.2216 70.0166 60.2216H72.0052C72.0527 60.2216 72.0744 60.2417 72.0729 60.2821L72.0427 67.4878C72.0369 67.5152 72.0239 67.5281 72.0038 67.5281H70.823C70.7755 67.5281 70.7524 67.508 70.7539 67.4677L70.7755 62.0691L70.0756 67.4979C70.0684 67.5181 70.054 67.5281 70.0339 67.5281H68.7825C68.735 67.5281 68.712 67.508 68.7134 67.4677L68.0611 62.0072L68.0323 67.4878C68.0265 67.5152 68.0136 67.5281 67.9934 67.5281H66.8126C66.7651 67.5281 66.742 67.508 66.7435 67.4677L66.7737 60.2619C66.7795 60.2345 66.7924 60.2216 66.8126 60.2216H68.8012C68.8487 60.2216 68.8704 60.2417 68.8689 60.2821L69.4017 65.5093L69.9705 60.2518H69.9748Z'
      fill='black'
    />
    <path
      d='M73.9925 65.0456C74.0012 65.0528 74.0098 65.0686 74.017 65.096L74.0112 67.4878C74.0055 67.5152 73.9925 67.5281 73.9724 67.5281H72.721C72.6735 67.5281 72.6504 67.508 72.6533 67.4677L72.6836 60.2619C72.6893 60.2345 72.7023 60.2216 72.7224 60.2216H74.2661C74.7298 60.2216 75.084 60.2806 75.3274 60.3987C75.5708 60.5168 75.7392 60.7328 75.8328 61.0496C75.9048 61.2915 75.938 61.5853 75.9322 61.928L75.9264 63.3003C75.9236 63.6301 75.8948 63.9022 75.8415 64.1182C75.7882 64.3342 75.6975 64.5056 75.5708 64.6323C75.444 64.7605 75.2756 64.8512 75.0668 64.9045C74.8565 64.9577 74.5944 64.9851 74.2791 64.9851H74.2186C74.1711 64.9851 74.148 64.9621 74.1466 64.9145L74.2013 63.6027C74.2013 63.5825 74.2157 63.5725 74.2431 63.5725H74.2834C74.3439 63.5725 74.3957 63.5609 74.4389 63.5365C74.4821 63.5134 74.5167 63.4673 74.5397 63.3997C74.5628 63.332 74.5815 63.2398 74.593 63.1217C74.606 63.0037 74.6103 62.851 74.6074 62.6624C74.6117 62.4262 74.6088 62.239 74.6016 62.0965C74.593 61.9553 74.5786 61.8473 74.5556 61.7739C74.5354 61.7062 74.5066 61.6659 74.4692 61.6529C74.4317 61.64 74.3784 61.6328 74.3122 61.6328H74.03L74.0228 63.5105L74.0084 63.5307L73.9954 64.9837C73.9896 65.011 73.9896 65.0312 73.994 65.0441'
      fill='black'
    />
    <path
      d='M76.7198 67.4677L76.7501 60.2619C76.7559 60.2345 76.7688 60.2216 76.789 60.2216H78.0403C78.0879 60.2216 78.1109 60.2417 78.1095 60.2821L78.0792 67.4878C78.0734 67.5137 78.0605 67.5281 78.0403 67.5281H76.789C76.7415 67.5281 76.7184 67.508 76.7213 67.4677'
      fill='black'
    />
    <path
      d='M78.805 60.9632C78.8726 60.7386 78.9749 60.56 79.1102 60.429C79.2456 60.2979 79.4155 60.2072 79.62 60.1568C79.823 60.1064 80.0664 60.0805 80.3501 60.0805C80.7734 60.0805 81.1162 60.1424 81.3739 60.2677C81.6331 60.3915 81.8146 60.6291 81.9182 60.979C81.9859 61.208 82.0205 61.4931 82.0219 61.8373L82.0075 65.9341C82.0018 66.2768 81.9658 66.5619 81.8981 66.7866C81.8304 67.0126 81.7282 67.1898 81.5928 67.3208C81.4574 67.4518 81.2846 67.5426 81.0787 67.593C80.8714 67.6434 80.6309 67.6693 80.3544 67.6693C79.931 67.6693 79.5869 67.6074 79.3248 67.4821C79.0627 67.3582 78.8798 67.1206 78.7747 66.7707C78.7402 66.657 78.7157 66.5288 78.7013 66.3877C78.6854 66.2466 78.6797 66.0882 78.6811 65.9139L78.6955 61.8171C78.7013 61.4744 78.7373 61.1893 78.805 60.9646M80.0376 66.0349C80.0405 66.068 80.0448 66.0925 80.0477 66.1054C80.0779 66.2062 80.1701 66.2566 80.3242 66.2566C80.4379 66.2566 80.5186 66.2293 80.5632 66.176C80.6078 66.1227 80.6338 66.0277 80.641 65.8938L80.6438 65.9038L80.664 61.8258C80.664 61.7595 80.6568 61.7019 80.6438 61.6544C80.6122 61.5464 80.5214 61.4931 80.3746 61.4931C80.1456 61.4931 80.039 61.6112 80.0563 61.8459L80.0362 65.9226C80.0347 65.9629 80.0362 66.0003 80.039 66.0334'
      fill='black'
    />
    <path
      d='M45.9615 73.3789C46.0291 73.1542 46.1314 72.9757 46.2667 72.8446C46.4021 72.7136 46.572 72.6229 46.7765 72.5725C46.9795 72.5221 47.2229 72.4962 47.5066 72.4962C47.9299 72.4962 48.2727 72.5581 48.5304 72.6834C48.7896 72.8072 48.9711 73.0448 49.0747 73.3947C49.1424 73.6237 49.177 73.9088 49.1784 74.253L49.164 78.3498C49.1583 78.6925 49.1223 78.9776 49.0546 79.2022C48.9869 79.4283 48.8847 79.6054 48.7493 79.7365C48.6139 79.8675 48.4411 79.9582 48.2352 80.0086C48.0279 80.059 47.7874 80.085 47.5109 80.085C47.0875 80.085 46.7434 80.023 46.4813 79.8977C46.2192 79.7739 46.0363 79.5363 45.9312 79.1864C45.8967 79.0726 45.8722 78.9445 45.8578 78.8034C45.8419 78.6622 45.8362 78.5038 45.8376 78.3296L45.852 74.2328C45.8578 73.8901 45.8938 73.605 45.9615 73.3803M47.1941 78.4506C47.197 78.4837 47.2013 78.5082 47.2042 78.5211C47.2344 78.6219 47.3266 78.6723 47.4807 78.6723C47.5944 78.6723 47.6751 78.6449 47.7197 78.5917C47.7643 78.5384 47.7903 78.4434 47.7975 78.3094L47.8003 78.3195L47.8205 74.2414C47.8205 74.1752 47.8133 74.1176 47.8003 74.0701C47.7687 73.9621 47.6779 73.9088 47.5311 73.9088C47.3021 73.9088 47.1955 74.0269 47.2128 74.2616L47.1927 78.3382C47.1912 78.3786 47.1927 78.416 47.1955 78.4491'
      fill='black'
    />
    <path
      d='M85.2576 60.2619C85.2634 60.2345 85.2763 60.2216 85.2965 60.2216H86.4773C86.5248 60.2216 86.5464 60.2417 86.545 60.2821L86.5147 67.4878C86.509 67.5137 86.496 67.5281 86.4758 67.5281H84.9019C84.8688 67.5281 84.8458 67.5109 84.8371 67.4777L83.868 62.8856L83.8522 67.4878C83.8464 67.5137 83.8334 67.5281 83.8133 67.5281H82.6224C82.5749 67.5281 82.5518 67.508 82.5533 67.4677L82.5835 60.2619C82.5893 60.2345 82.6022 60.2216 82.6224 60.2216H84.2165C84.2496 60.2216 84.2726 60.2389 84.2827 60.272L85.236 64.9448L85.259 60.2619H85.2576Z'
      fill='black'
    />
    <path
      d='M88.9065 62.4219L88.9181 62.0187L88.921 62.0288C88.9195 61.8877 88.908 61.7797 88.885 61.7062C88.8432 61.5651 88.7553 61.4945 88.62 61.4945C88.5062 61.4945 88.4241 61.5378 88.3766 61.6256C88.3291 61.7134 88.3075 61.8574 88.3133 62.059C88.3104 62.1195 88.3133 62.1728 88.3205 62.2203C88.3277 62.2678 88.3377 62.3139 88.3521 62.3614C88.404 62.5371 88.4933 62.6883 88.6185 62.815C88.7438 62.9432 88.8835 63.0742 89.0376 63.2082C89.137 63.296 89.2392 63.3882 89.3414 63.4861C89.4451 63.584 89.5445 63.6934 89.641 63.8144C89.7374 63.9353 89.8281 64.0707 89.9131 64.2176C89.9966 64.3659 90.0672 64.5301 90.1205 64.7115C90.1608 64.8469 90.1925 64.9923 90.2155 65.1507C90.2385 65.3091 90.2501 65.479 90.2501 65.6605C90.2501 66.0435 90.2213 66.3632 90.1665 66.6195C90.1118 66.8758 90.0197 67.0818 89.8915 67.2402C89.7633 67.3986 89.5934 67.5094 89.3803 67.5728C89.1672 67.6362 88.9051 67.6693 88.597 67.6693H88.4155C88.368 67.6693 88.3449 67.6448 88.3435 67.5987L88.4155 66.2768C88.4184 66.2437 88.4357 66.2336 88.4673 66.2465C88.4961 66.2537 88.5307 66.2566 88.571 66.2566C88.692 66.2566 88.7769 66.2134 88.8245 66.1256C88.872 66.0378 88.8979 65.8966 88.9008 65.7022C88.8979 65.5539 88.8777 65.4229 88.8446 65.3091C88.7841 65.1075 88.6862 64.9361 88.5509 64.795C88.4141 64.6539 88.2686 64.5128 88.1117 64.3717C88.0123 64.2838 87.9101 64.1917 87.8078 64.0937C87.7041 63.9958 87.6077 63.8878 87.5155 63.7712C87.4233 63.6531 87.3384 63.5264 87.2606 63.3882C87.1829 63.2499 87.1181 63.0944 87.0662 62.9187C86.9841 62.6422 86.9438 62.3225 86.9424 61.9597C86.9453 61.6299 86.9755 61.3462 87.0317 61.1072C87.0878 60.8681 87.1814 60.6737 87.3125 60.5211C87.4421 60.3699 87.6134 60.259 87.8237 60.1885C88.0353 60.1179 88.2917 60.0819 88.5941 60.0819C89.0117 60.0819 89.3472 60.1553 89.6035 60.3037C89.8598 60.452 90.0398 60.7011 90.1435 61.051C90.2112 61.28 90.2443 61.5593 90.24 61.8891L90.2357 62.455C90.2299 62.4824 90.2169 62.4954 90.1968 62.4954H88.9757C88.9281 62.4954 88.9051 62.4723 88.9037 62.4248'
      fill='black'
    />
    <path
      d='M90.7151 60.2216H91.9665C92.014 60.2216 92.0371 60.2417 92.0356 60.2821L92.027 63.0065H92.6419L92.6548 60.2619C92.6606 60.2345 92.6735 60.2216 92.6937 60.2216H93.9451C93.9926 60.2216 94.0156 60.2417 94.0142 60.2821L93.9839 67.4878C93.9782 67.5152 93.9652 67.5281 93.9451 67.5281H92.6937C92.6462 67.5281 92.6231 67.508 92.6246 67.4677L92.6275 64.4206H92.0126L92.0054 67.4893C91.9996 67.5166 91.9867 67.5296 91.9665 67.5296H90.7151C90.6676 67.5296 90.6446 67.5094 90.6475 67.4691L90.6777 60.2633C90.6835 60.236 90.6964 60.223 90.7166 60.223'
      fill='black'
    />
    <path
      d='M94.6637 67.4677L94.6939 60.2619C94.6997 60.2345 94.7127 60.2216 94.7328 60.2216H95.9842C96.0317 60.2216 96.0547 60.2417 96.0533 60.2821L96.0231 67.4878C96.0173 67.5137 96.0043 67.5281 95.9842 67.5281H94.7328C94.6853 67.5281 94.6623 67.508 94.6651 67.4677'
      fill='black'
    />
    <path
      d='M98.0564 65.096L98.0506 67.4878C98.0448 67.5152 98.0319 67.5281 98.0117 67.5281H96.7604C96.7128 67.5281 96.6898 67.508 96.6912 67.4677L96.7215 60.2619C96.7272 60.2345 96.7402 60.2216 96.7604 60.2216H98.304C98.7677 60.2216 99.1219 60.2806 99.3653 60.3987C99.6087 60.5168 99.7771 60.7328 99.8707 61.0496C99.9427 61.2915 99.9759 61.5838 99.9701 61.928L99.9644 63.3003C99.9615 63.6301 99.9327 63.9022 99.8794 64.1182C99.8261 64.3342 99.7354 64.5056 99.6087 64.6323C99.4819 64.7605 99.3135 64.8512 99.1047 64.9045C98.8944 64.9577 98.6323 64.9851 98.3155 64.9851H98.2551C98.2075 64.9851 98.1845 64.9621 98.1831 64.9145L98.2378 63.6027C98.2378 63.5825 98.2522 63.5725 98.2795 63.5725H98.3199C98.3803 63.5725 98.4322 63.5609 98.4754 63.5365C98.5186 63.5134 98.5517 63.4673 98.5762 63.3997C98.5992 63.332 98.6179 63.2398 98.6295 63.1217C98.6424 63.0037 98.6467 62.851 98.6453 62.6624C98.6496 62.4277 98.6467 62.239 98.6395 62.0979C98.6309 61.9568 98.6165 61.8488 98.5935 61.7753C98.5733 61.7077 98.5445 61.6673 98.5071 61.6544C98.4696 61.6414 98.4163 61.6342 98.3501 61.6342H98.0679L98.0607 63.512'
      fill='black'
    />
    <path
      d='M34.846 67.4086C34.715 67.4432 34.6358 67.449 34.5105 67.4691C34.3852 67.4893 34.2571 67.5008 34.1275 67.5037C33.9979 67.5066 33.8712 67.5094 33.7502 67.5094H33.5688C33.4075 67.5094 33.2404 67.4922 33.0691 67.459C32.8977 67.4259 32.7364 67.3698 32.5852 67.292C32.434 67.2142 32.3016 67.1149 32.1892 66.9939C32.0755 66.873 31.9934 66.7246 31.9416 66.5504C31.9113 66.4496 31.8955 66.3286 31.894 66.1875L31.9185 61.8171C31.9113 61.2051 32.0337 60.763 32.2857 60.4894C32.5377 60.2158 32.9539 60.0805 33.5313 60.0805C33.6048 60.0805 33.6753 60.0819 33.7401 60.0862C33.8049 60.0891 33.8654 60.0949 33.9216 60.1006C33.9691 60.1006 33.9921 60.1237 33.9936 60.1712L33.9288 61.5838C33.923 61.6112 33.91 61.6242 33.8899 61.6242C33.8697 61.6242 33.8496 61.6112 33.828 61.5838C33.7905 61.5234 33.7142 61.4931 33.599 61.4931C33.37 61.4931 33.2635 61.6112 33.2793 61.8459L33.2592 65.9226C33.2577 65.9629 33.2592 66.0003 33.262 66.0334C33.2649 66.0666 33.2692 66.091 33.2736 66.104C33.3038 66.2048 33.396 66.2552 33.55 66.2552C33.6638 66.2552 33.7444 66.2293 33.7891 66.1746C33.8337 66.1213 33.8596 66.0262 33.8668 65.8923L33.9187 63.2787C33.9187 63.2586 33.9331 63.2485 33.9604 63.2485H35.1614C35.2089 63.2485 35.232 63.2686 35.2305 63.309L35.2132 67.1941C35.2075 67.2214 35.2046 67.2646 35.1024 67.3222'
      fill='black'
    />
    <path
      d='M52.5263 67.4086C52.3953 67.4432 52.3161 67.449 52.1908 67.4691C52.0655 67.4893 51.9374 67.5008 51.8078 67.5037C51.6782 67.5066 51.5515 67.5094 51.4305 67.5094H51.2491C51.0878 67.5094 50.9207 67.4922 50.7494 67.459C50.578 67.4259 50.4167 67.3698 50.2655 67.292C50.1143 67.2142 49.9819 67.1149 49.8695 66.9939C49.7558 66.873 49.6737 66.7246 49.6219 66.5504C49.5916 66.4496 49.5758 66.3286 49.5743 66.1875L49.5988 61.8171C49.5916 61.2051 49.714 60.763 49.966 60.4894C50.218 60.2158 50.6342 60.0805 51.2116 60.0805C51.2851 60.0805 51.3556 60.0819 51.4204 60.0862C51.4852 60.0891 51.5457 60.0949 51.6019 60.1006C51.6494 60.1006 51.6724 60.1237 51.6724 60.1712L51.6076 61.5838C51.6019 61.6112 51.5889 61.6242 51.5687 61.6242C51.5486 61.6242 51.5284 61.6112 51.5068 61.5838C51.4694 61.5234 51.3931 61.4931 51.2779 61.4931C51.0489 61.4931 50.9423 61.6112 50.9582 61.8459L50.938 65.9226C50.9366 65.9629 50.938 66.0003 50.9409 66.0334C50.9438 66.0666 50.9481 66.091 50.9524 66.104C50.9827 66.2048 51.0748 66.2552 51.2289 66.2552C51.3427 66.2552 51.4233 66.2293 51.4679 66.1746C51.5126 66.1213 51.5385 66.0262 51.5457 65.8923L51.5975 63.2787C51.5975 63.2586 51.6119 63.2485 51.6393 63.2485H52.8403C52.8878 63.2485 52.9108 63.2686 52.9094 63.309L52.8921 67.1941C52.8863 67.2214 52.8835 67.2646 52.7798 67.3222'
      fill='black'
    />
    <path
      d='M0.189575 21.3301V28.1672H32.424L28.7592 21.3301H0.189575Z'
      fill='#E93725'
    />
    <path
      d='M32.7623 21.3301L36.4617 28.1701H62.9418L59.3303 21.3301H32.7623Z'
      fill='#E93725'
    />
    <path
      d='M63.6101 21.3301L67.2936 28.1701H88.0455V33.7371H101.838V28.0693L98.2911 21.3301H63.6101Z'
      fill='#E93725'
    />
    <path
      d='M9.39982 30.261V45.8302H0.189575V52.6674H36.1032L32.5003 45.8302H23.2137V30.261H9.39982Z'
      fill='#E93725'
    />
    <path
      d='M36.4459 30.261V45.8518L40.1381 52.6674H50.2181V40.2733H62.9606V33.7371H50.2642V30.261H36.4459Z'
      fill='#E93725'
    />
    <path
      d='M67.3008 30.2653V45.8763L70.8993 52.6673H98.2709L101.819 45.8547V40.256H88.0454V45.8101H81.1032V30.2653H67.3008Z'
      fill='#E93725'
    />
  </svg>
)
