export const IFCLogo = () => (
  <svg
    width='88'
    height='27'
    viewBox='0 0 88 27'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_1_1052)'>
      <path d='M25.0326 0H0.5V5.91225H28.1471L25.0326 0Z' fill='#EB3826' />
      <path
        d='M20.2834 21.0878V7.62018H8.36367V21.0878H0.5V27H31.3884L28.2727 21.0878H20.2834Z'
        fill='#EB3826'
      />
      <path
        d='M28.493 0L31.6088 5.91225H54.107L50.9925 0H28.493Z'
        fill='#EB3826'
      />
      <path
        d='M84.3842 0H54.8423L57.958 5.91225H75.4597V10.7079H87.5V5.91225L84.3842 0Z'
        fill='#EB3826'
      />
      <path
        d='M75.4598 21.0878H69.7485V7.62018H57.8287V21.0878L60.9432 27H84.3843L87.5001 21.0878V16.2921H75.4598V21.0878Z'
        fill='#EB3826'
      />
      <path
        d='M43.3991 7.62018H31.4794V21.0878L34.5938 27H43.3991V21.0878V16.2921H54.107V10.446H43.3991V7.62018Z'
        fill='#EB3826'
      />
    </g>
    <defs>
      <clipPath id='clip0_1_1052'>
        <rect width='87' height='27' fill='white' transform='translate(0.5)' />
      </clipPath>
    </defs>
  </svg>
)
