import { generatePath } from 'react-router-dom'

import {
  ErrorResponse,
  MutationRequestFunction,
  SuccessResponse
} from '../../types'
import { apiRoutes } from '../../apiRoutes'
import { ErrorType } from '../../commonTypes'

import { ProcessPaymentResponse } from './types'

export type ProcessPaymentProps = {
  eventId: number
}

export type ProcessPaymentSuccessResponse =
  SuccessResponse<ProcessPaymentResponse>
export type ProcessPaymentErrorResponse = ErrorResponse<ErrorType>

export const processPayment: MutationRequestFunction<
  ProcessPaymentSuccessResponse,
  ProcessPaymentProps
> =
  ({ request }) =>
  async ({ eventId }) =>
    request.post(
      generatePath(apiRoutes.postProcessPayment, {
        eventId: String(eventId)
      })
    )
