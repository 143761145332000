import { useCallback, useState } from 'react'

export const useStreamModal = () => {
  const [isOpen, setOpen] = useState(false)

  const onOpen = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const onCancel = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const onOpenHandler = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const onOpenStreamModal = () => {
    onOpenHandler()
  }

  return {
    onOpenStreamModal,
    streamModalModalProps: {
      isOpen,
      setOpen,
      onCancel,
      onOpen
    }
  }
}
