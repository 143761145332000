import { MutationOptions, useMutation } from 'react-query'

import { useRequest } from '../../../hooks/useRequest'

import {
  checkPayment,
  CheckPaymentErrorResponse,
  CheckPaymentProps,
  CheckPaymentSuccessResponse
} from './postCheckPayment'

export const usePostCheckPaymentAPI = (
  options: MutationOptions<
    CheckPaymentSuccessResponse,
    CheckPaymentErrorResponse,
    CheckPaymentProps
  > = {}
) => {
  const requestContext = useRequest()

  const { isLoading, mutate } = useMutation<
    CheckPaymentSuccessResponse,
    CheckPaymentErrorResponse,
    CheckPaymentProps
  >(checkPayment(requestContext), options)

  return {
    isLoading,
    checkPayment: mutate
  }
}
