import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'core-js'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import relativeTime from 'dayjs/plugin/relativeTime'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { SnackbarProvider } from 'notistack'

import { Notification } from './atoms/Notification'
import { notificationSettings, queryConfig } from './config'
import { ApplicationRouter } from './layout/ApplicationRouter'
import { theme } from './styles/theme'

const queryClient = new QueryClient(queryConfig)
const root = createRoot(document.getElementById('root') as HTMLElement)

dayjs.extend(relativeTime)
dayjs.extend(isBetween)
dayjs.extend(utc)
dayjs.extend(timezone)

root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <SnackbarProvider
          Components={{
            success: Notification,
            error: Notification
          }}
          anchorOrigin={notificationSettings}
        >
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <CssBaseline />
            <ApplicationRouter />
          </BrowserRouter>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
  // </React.StrictMode>
)
