import { useCallback, useMemo, useState } from 'react'

import { useNotification } from '../../../atoms/Notification'
import { useGetCurrentUserAPI } from '../../../api/users/getCurrentUser'
import { usePostSmsSendAPI } from '../../../api/auth/postSmsSend'
import { usePostSmsVerifyAPI } from '../../../api/auth/postSmsVerify'

import { IVerifyPhoneModalFields, IVerifyPhoneModalFormValues } from './types'

export const useVerifyPhoneModal = () => {
  const { openSuccessNotification, openErrorNotification } = useNotification()

  const { isLoading: getUserLoading, user, getUser } = useGetCurrentUserAPI()

  const [isOpen, setOpen] = useState(false)

  const onOpen = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const onCancel = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const { isLoading: sendSmsLoading, smsSend } = usePostSmsSendAPI({
    onSuccess: async () => {
      openSuccessNotification('SMS Sent Successfully')
    },
    onError: () => {
      openErrorNotification('SMS Was Not Sent', 'Please try again')
    }
  })

  const handleOpenSmsVerificationModal = useCallback(() => {
    smsSend({ phone: user?.phoneNumber || '' })

    onOpen()
  }, [setOpen, user])

  const handleOpenSmsVerificationModalWithPhone = useCallback(
    (phoneNumber: string) => {
      smsSend({ phone: phoneNumber })

      onOpen()
    },
    [onOpen]
  )

  const { isLoading: smsVerifyLoading, smsVerify } = usePostSmsVerifyAPI({
    onSuccess: async () => {
      openSuccessNotification('SMS Verified Successfully')

      getUser()

      onCancel()
    },
    onError: () => {
      openErrorNotification('SMS Was Not Verified', 'Please try again')
    }
  })

  const handleSubmit = (userFormValues: IVerifyPhoneModalFormValues) => {
    const phoneVerificaitonDTO = {
      code: +userFormValues.code,
      phone: user?.phoneNumber || ''
    }

    smsVerify(phoneVerificaitonDTO)
  }

  const initialValues = useMemo(
    () => ({
      [IVerifyPhoneModalFields.CODE]: ''
    }),
    []
  )

  return {
    handleOpenSmsVerificationModal,
    handleOpenSmsVerificationModalWithPhone,
    verifyPhoneModalProps: {
      isLoading: smsVerifyLoading || sendSmsLoading || getUserLoading,
      isOpen,
      setOpen,
      initialValues,
      handleSubmit
    }
  }
}
