import { ActionModalProps } from '../../../atoms/Modal'

export enum IUserLoginFormFields {
  EMAIL = 'email',
  PASSWORD = 'password'
}

export interface IPostUserLoginFormValues {
  [IUserLoginFormFields.EMAIL]: string
  [IUserLoginFormFields.PASSWORD]: string
}

export const initialValues: IPostUserLoginFormValues = {
  [IUserLoginFormFields.EMAIL]: '',
  [IUserLoginFormFields.PASSWORD]: ''
}

export type UserLoginModalProps = Pick<
  ActionModalProps,
  'isOpen' | 'setOpen'
> & {
  isLoading: boolean
  setOpen: (value: boolean) => void
  handleSubmit: (values: IPostUserLoginFormValues) => void
  handleForgetPassword: (email?: string) => void
  handleOpenRegistration: () => void
}
