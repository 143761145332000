import { styled, Typography } from '@mui/material'

export const AccentTypography = styled(Typography)(({ theme, onClick }) => ({
  ...theme.typography.bodyMedium,
  color: theme.palette.neutral.lighter,
  width: 'fit-content',
  height: 'fit-content',
  cursor: onClick ? 'pointer' : 'default',
  borderBottom: `1px dashed ${theme.palette.neutral.mediumLight}`,
  '&:hover': {
    borderBottom: `1px solid ${theme.palette.neutral.mediumDark}`
  }
})) as typeof Typography
