import { MutationOptions, useMutation } from 'react-query'

import { useRequest } from '../../../hooks/useRequest'

import {
  passwordRecoveryVerify,
  PasswordRecoveryVerifyErrorResponse,
  PasswordRecoveryVerifyProps,
  PasswordRecoveryVerifySuccessResponse
} from './passwordRecoveryVerify'

export const usePostPasswordRecoveryVerifyAPI = (
  options: MutationOptions<
    PasswordRecoveryVerifySuccessResponse,
    PasswordRecoveryVerifyErrorResponse,
    PasswordRecoveryVerifyProps
  > = {}
) => {
  const requestContext = useRequest()

  const { isLoading, mutate } = useMutation<
    PasswordRecoveryVerifySuccessResponse,
    PasswordRecoveryVerifyErrorResponse,
    PasswordRecoveryVerifyProps
  >(passwordRecoveryVerify(requestContext), options)

  return {
    isLoading,
    passwordRecoveryVerify: mutate
  }
}
