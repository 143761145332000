import { FC, useRef } from 'react'
import { Stack } from '@mui/material'
import { Form, Formik, FormikConfig, FormikProps } from 'formik'

import { ActionModal } from '../../../atoms/Modal'
import { MainTypography } from '../../../atoms/Typography'
import { FilledButton } from '../../../atoms/FilledButton'
import { TextInputField } from '../../../atoms/TextInputField'

import {
  IVerifyPhoneModalFields,
  IVerifyPhoneModalFormValues,
  VerifyPhoneModalProps
} from './types'
import { phoneVerificationSchema } from './loginUserValidation'

export const VerifyPhoneModal: FC<VerifyPhoneModalProps> = ({
  isOpen,
  setOpen,
  isLoading,
  initialValues,
  handleSubmit
}) => {
  const formRef = useRef<FormikProps<typeof initialValues>>(null)

  const handleSubmitForm: FormikConfig<IVerifyPhoneModalFormValues>['onSubmit'] =
    async (values) => {
      await handleSubmit(values)
    }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <ActionModal isOpen={isOpen} setOpen={handleClose}>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange
        validationSchema={phoneVerificationSchema}
        onSubmit={handleSubmitForm}
      >
        <Form>
          <Stack
            sx={{
              width: '100%',
              marginBottom: 3,
              textAlign: 'center'
            }}
          >
            <MainTypography
              sx={{
                color: 'black'
              }}
            >
              SMS Verification
            </MainTypography>
          </Stack>
          <Stack rowGap={3}>
            <MainTypography
              sx={{
                color: 'black'
              }}
            >
              An SMS code has been sent to your phone number. Please enter the
              code below.
            </MainTypography>
            <TextInputField
              name={IVerifyPhoneModalFields.CODE}
              label='SMS Code'
            />
            <Stack
              rowGap={2}
              sx={{
                width: '70%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: 'auto'
              }}
            >
              <FilledButton
                disabled={isLoading}
                onClick={() => formRef.current?.submitForm()}
              >
                Submit
              </FilledButton>
            </Stack>
          </Stack>
        </Form>
      </Formik>
    </ActionModal>
  )
}
