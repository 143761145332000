export const colors = {
  primary: {
    lightest: '#FDFCFF',
    lighter: '#EBF1FF',
    light: '#D4E3FF',
    medium: '#A5C8FF',
    dark: '#4192F1',
    darker: '#1778D5',
    main: '#0071CE',
    darkerMain: '#004785',
    darkest: '#00315E'
  },
  secondary: {
    light: '#E4DFFF',
    main: '#6B5FCF',
    darkerMain: '#673ab7',
    dark: '#2B158D'
  },
  neutral: {
    lightest: '#FDFCFF',
    light: '#EEF0FA',
    lightMedium: '#E0E2EC',
    mediumLight: '#C3C6CF',
    medium: '#A8ABB4',
    main: '#A8ABB4',
    mediumDark: '#74777F',
    darkMedium: '#5B5E66',
    dark: '#43474E',
    darkest: '#2D3138',
    extraDark: '#181C22'
  },
  alert: {
    low: '#FBEAEA',
    medium: '#FFB4AB',
    high: '#D32F2F',
    extraHigh: '#7F1C1C'
  },
  success: {
    normal: '#EAF2EB',
    high: '#2E7D32'
  },
  warning: {
    normal: '#FCF8E6',
    high: '#E4B409',
    main: '#ED6C02'
  },
  info: {
    low: '#B9B9B9',
    normal: '#F2F3F5',
    high: '#79869F'
  },
  temperature: {
    normal: '#09A65F',
    high: '#DE3730',
    low: '#4192F1'
  },
  temperatureLineChart: {
    chartLine: '#92989D',
    chartLineWithTransparent: 'rgba(146, 152, 157, .4)',
    backgroundLine: '#E0E2EC',
    legend: '#3E4E67'
  },
  surface: {
    main: '#040E41'
  },
  status: {
    active: '#22B036',
    activeBG: '#E9F8EB',
    inactive: '#DE3730',
    inactiveBG: '#FBEAEA',
    unassignedBG: '#fdf1e6',
    pending: '#FF7235',
    pendingBG: '#FFEAE0',
    archive: '#74777F',
    archiveBG: '#EFEFF0'
  },
  badge: {
    active: '#1C872B',
    pending: '#704AFF',
    pendingBG: '#F0ECFF',
    archive: '#5B5D64',
    unassigned: '#602c03',
    outBG: '#EBF5F6',
    out: '#00515A',
    in: '#3361AC',
    new: '#8dc642'
  },
  tag: {
    activeBG: '#A2EAAB',
    inactiveBG: '#A5C8FF'
  },
  patientNote: {
    withNote: '#A2EAAB',
    withoutNote: '#EEF0FA'
  },
  common: {
    white: '#FFFFFF'
  }
}
