import { MutationOptions, useMutation } from 'react-query'

import { useRequest } from '../../../hooks/useRequest'

import {
  smsSend,
  SmsSendErrorResponse,
  SmsSendProps,
  SmsSendSuccessResponse
} from './smsSend'

export const usePostSmsSendAPI = (
  options: MutationOptions<
    SmsSendSuccessResponse,
    SmsSendErrorResponse,
    SmsSendProps
  > = {}
) => {
  const requestContext = useRequest()

  const { isLoading, mutate } = useMutation<
    SmsSendSuccessResponse,
    SmsSendErrorResponse,
    SmsSendProps
  >(smsSend(requestContext), options)

  return {
    isLoading,
    smsSend: mutate
  }
}
