import { generatePath } from 'react-router-dom'

import {
  ErrorResponse,
  QueryRequestFunction,
  SuccessResponse
} from '../../types'
import { apiRoutes } from '../../apiRoutes'
import { ErrorType } from '../../commonTypes'

import { GetUserResponse } from './types'

export type GetUserSuccessResponse = SuccessResponse<GetUserResponse>
export type GetUserErrorResponse = ErrorResponse<ErrorType>

export const getUser: QueryRequestFunction<GetUserSuccessResponse> =
  ({ request }) =>
  async () => {
    return request.get(generatePath(apiRoutes.getCurrentUser))
  }
