import { MutationOptions, useMutation } from 'react-query'

import { useRequest } from '../../../hooks/useRequest'

import {
  userLogOut,
  UserLogOutErrorResponse,
  UserLogOutSuccessResponse
} from './userLogOut'

export const usePostUserLogOutAPI = (
  options: MutationOptions<
    UserLogOutSuccessResponse,
    UserLogOutErrorResponse
  > = {}
) => {
  const requestContext = useRequest()

  const { isLoading, mutate } = useMutation<
    UserLogOutSuccessResponse,
    UserLogOutErrorResponse
  >(userLogOut(requestContext), options)

  return {
    isLoading,
    userLogout: mutate
  }
}
