import { useCallback, useState } from 'react'

import { useUserLoginAPI } from '../../../api/auth/postUserLogin'
import { useNotification } from '../../../atoms/Notification'
import { localStorageKeys } from '../../../constants/localStorageKeys'
import { useRequest } from '../../../hooks/useRequest'
import { usePostPasswordRecoverySendAPI } from '../../../api/auth/postPasswordRecoverySend'
import { useGetCurrentUserAPI } from '../../../api/users/getCurrentUser'

import { IPostUserLoginFormValues } from './types'

export const useUserLoginModal = (onOpenRegistration: () => void) => {
  const requestContext = useRequest()

  const { openSuccessNotification, openErrorNotification } = useNotification()

  const [isOpen, setOpen] = useState(false)

  const onOpen = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const onCancel = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const onOpenHandler = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const { isLoading: getUserLoading, getUser } = useGetCurrentUserAPI()

  const { isLoading, userLogin } = useUserLoginAPI({
    onSuccess: ({ data: responseData }) => {
      localStorage.setItem(
        localStorageKeys.IFCsession,
        JSON.stringify(responseData)
      )

      requestContext.setSession({
        token: responseData.token,
        userId: responseData.user.id
      })

      openSuccessNotification('User Logged In Successfully')

      getUser()

      setOpen(false)
    },
    onError: ({ response }) => {
      switch (response?.data?.message) {
        case 'Password or email incorrect':
          openErrorNotification(
            'User Login Failed',
            'Passowrd Or Email Is Incorrect'
          )
          break

        default:
          openErrorNotification('User login failed', 'Please try again later')
          break
      }
    }
  })

  const { isLoading: passwordRecoverySendLoading, passwordRecoverySend } =
    usePostPasswordRecoverySendAPI({
      onSuccess: () => {
        openSuccessNotification(
          'Password Recovery Email Sent',
          'Please check your email'
        )
      },
      onError: () => {
        openErrorNotification(
          'Password Recovery Email Failed',
          'Please try again later'
        )
      }
    })

  const onOpenUserLogin = () => {
    onOpenHandler()
  }

  const onSubmit = (userFormValues: IPostUserLoginFormValues) => {
    const userLoginDTO = {
      email: userFormValues.email,
      password: userFormValues.password
    }
    userLogin(userLoginDTO)
  }

  const handleForgetPassword = (email?: string) => {
    if (email) {
      passwordRecoverySend({ email })
    }
  }

  const handleOpenRegistration = () => {
    onOpenRegistration()
    onCancel()
  }

  return {
    onOpenUserLogin,
    userLoginModalProps: {
      isLoading: isLoading || passwordRecoverySendLoading || getUserLoading,
      isOpen,
      setOpen,
      handleSubmit: onSubmit,
      onCancel,
      onOpen,
      handleForgetPassword,
      handleOpenRegistration
    }
  }
}
