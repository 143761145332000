import { MutationOptions, useMutation } from 'react-query'

import { useRequest } from '../../../hooks/useRequest'

import {
  freeEventPayment,
  FreeEventPaymentErrorResponse,
  FreeEventPaymentProps,
  FreeEventPaymentSuccessResponse
} from './freeEventPayment'

export const usePostFreeEventPaymentAPI = (
  options: MutationOptions<
    FreeEventPaymentSuccessResponse,
    FreeEventPaymentErrorResponse,
    FreeEventPaymentProps
  > = {}
) => {
  const requestContext = useRequest()

  const { isLoading, mutate } = useMutation<
    FreeEventPaymentSuccessResponse,
    FreeEventPaymentErrorResponse,
    FreeEventPaymentProps
  >(freeEventPayment(requestContext), options)

  return {
    isLoading,
    freeEventPayment: mutate
  }
}
