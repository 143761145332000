import { FC, useCallback } from 'react'
import { FieldValidator, useField } from 'formik'

import { Input, InputProps } from '../Input'

interface TextInputFieldProps {
  name: string
  validate?: FieldValidator
  counter?: boolean
}

export const TextInputField: FC<TextInputFieldProps & InputProps> = ({
  name,
  validate,
  counter,
  ...props
}) => {
  const [field, meta, helpers] = useField<string>({ name, validate })
  const hasError = Boolean(meta && meta.error)

  const handleFocus = useCallback(() => {
    helpers.setError('')
  }, [helpers])

  return (
    <Input
      error={!!meta.error}
      helperText={hasError ? meta.error : ''}
      {...props}
      {...field}
      onFocus={handleFocus}
      counter={counter}
    />
  )
}
