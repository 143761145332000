import { MutationOptions, useMutation } from 'react-query'

import { useRequest } from '../../../hooks/useRequest'

import {
  emailVerify,
  EmailVerifyErrorResponse,
  EmailVerifyProps,
  EmailVerifySuccessResponse
} from './emailVerify'

export const usePostEmailVerifyAPI = (
  options: MutationOptions<
    EmailVerifySuccessResponse,
    EmailVerifyErrorResponse,
    EmailVerifyProps
  > = {}
) => {
  const requestContext = useRequest()

  const { isLoading, mutate } = useMutation<
    EmailVerifySuccessResponse,
    EmailVerifyErrorResponse,
    EmailVerifyProps
  >(emailVerify(requestContext), options)

  return {
    isLoading,
    emailVerify: mutate
  }
}
