import { useCallback, useState } from 'react'

import { usePostEmailSendAPI } from '../../../api/auth/postEmailSend'
import { useNotification } from '../../../atoms/Notification'
import { useGetCurrentUserAPI } from '../../../api/users/getCurrentUser'
import { UserEntityType } from '../../../api/users/getCurrentUser/types'

export const useSendEmailModal = (user?: UserEntityType) => {
  const { openSuccessNotification, openErrorNotification } = useNotification()

  const { getUser } = useGetCurrentUserAPI()

  const [isOpen, setOpen] = useState(false)

  const onOpen = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const onCancel = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const onOpenHandler = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const { isLoading, emailSend } = usePostEmailSendAPI({
    onSuccess: () => {
      openSuccessNotification('Email Was Sent Successfully')

      getUser()

      setOpen(false)
    },
    onError: () => {
      openErrorNotification('Email Was Not Sent')
    }
  })

  const onOpenEmailSend = () => {
    onOpenHandler()
  }

  const onSubmit = () => {
    if (user) {
      const sendEmailDTO = {
        email: user.email
      }
      emailSend(sendEmailDTO)
    }
  }

  return {
    onOpenEmailSend,
    sendEmailModalProps: {
      isOpen,
      setOpen,
      handleSubmit: onSubmit,
      onCancel,
      onOpen,
      isLoading,
      userInfo: user
    }
  }
}
