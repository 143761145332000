import { MutationOptions, useMutation } from 'react-query'

import { useRequest } from '../../../hooks/useRequest'

import {
  smsVerify,
  SmsVerifyErrorResponse,
  SmsVerifyProps,
  SmsVerifySuccessResponse
} from './smsVerify'

export const usePostSmsVerifyAPI = (
  options: MutationOptions<
    SmsVerifySuccessResponse,
    SmsVerifyErrorResponse,
    SmsVerifyProps
  > = {}
) => {
  const requestContext = useRequest()

  const { isLoading, mutate } = useMutation<
    SmsVerifySuccessResponse,
    SmsVerifyErrorResponse,
    SmsVerifyProps
  >(smsVerify(requestContext), options)

  return {
    isLoading,
    smsVerify: mutate
  }
}
