import { generatePath } from 'react-router-dom'

import {
  ErrorResponse,
  MutationRequestFunction,
  SuccessResponse
} from '../../types'
import { apiRoutes } from '../../apiRoutes'
import { ErrorType } from '../../commonTypes'

import { SmsSendResponse } from './types'

export type SmsSendProps = {
  phone: string
}

export type SmsSendSuccessResponse = SuccessResponse<SmsSendResponse>
export type SmsSendErrorResponse = ErrorResponse<ErrorType>

export const smsSend: MutationRequestFunction<
  SmsSendSuccessResponse,
  SmsSendProps
> =
  ({ request }) =>
  async (phone) =>
    request.post(generatePath(apiRoutes.postSmsSend), phone)
