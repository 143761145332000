import { IconButton as MUIIconButton, styled } from '@mui/material'

export type { IconButtonProps } from '@mui/material'

export const IconButton = styled(MUIIconButton)(({ theme }) => ({
  padding: 0,
  height: 40,
  width: 40,
  backgroundColor: theme.palette.primary.lighter,
  color: theme.palette.primary.main,
  borderRadius: '4px',
  '&:disabled': {
    color: theme.palette.neutral.medium,
    backgroundColor: theme.palette.neutral.light
  }
}))
