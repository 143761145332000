export const apiRoutes = {
  // Auth
  postUserLogin: 'auth/login',
  postUserLogout: 'auth/logout',
  postEmailSend: '/auth/send-email',
  postEmailVerify: '/auth/email-verify',
  postSmsSend: '/auth/send-sms',
  postSmsVerify: '/auth/sms-verify',
  postPasswordRecoverySend: 'auth/recovery-request',
  postPasswordRecoveryVerify: '/auth/recovery-verify',

  // Users
  postUserRegistration: '/users/sign-up',
  getCurrentUser: '/users/me',

  // Events
  getCurrentEvent: '/events/current-event',

  // Payment
  postProcessPayment: '/payment/process-payments/:eventId',
  postFreeEventPayment: '/payment/free-event/:eventId',
  postCheckPayment: '/payment/check-payment/:paymentId',

  // Security
  refreshToken: '/auth/refresh-access-token',
  getEmail: '/user/email',
  token: '/token',
  validateToken: '/token/validate'
} as const
