import { Drawer, Grid, Stack } from '@mui/material'
import { Show } from 'react-extend-jsx'
import { useEffect, useMemo, useState } from 'react'
import { Close as CloseIcon, Menu as MenuIcon } from '@mui/icons-material'

import { AppBar } from '../../../atoms/AppBar'
import { IFCLogo } from '../../../assets/icons/IFCLogo'
import { OutlinedButton } from '../../../atoms/OutlinedButton'
import { MainTypography } from '../../../atoms/Typography'
import {
  UserRegistrationModal,
  useUserRegistrationModal
} from '../../modals/UserRegistrationModal'
import { UserLoginModal, useUserLoginModal } from '../../modals/UserLoginModal'
import { useGetCurrentUserAPI } from '../../../api/users/getCurrentUser'
import { ActionMenu } from '../../../atoms/ActionMenu'
import { useSendEmailModal } from '../../modals/SendEmailModal/useSendEmailModal'
import { SendEmailModal } from '../../modals/SendEmailModal/SendEmailModal'
import {
  useVerifyEmailModal,
  VerifyEmailModal
} from '../../modals/VerifyEmailModal'
import {
  useVerifyPasswordRecoveryModal,
  VerifyPasswordRecoveryModal
} from '../../modals/VerifyPasswordRecoveryModal'
import {
  useVerifyPhoneModal,
  VerifyPhoneModal
} from '../../modals/VerifyPhoneModal'
import {
  PaymentResultModal,
  usePaymentResultModal
} from '../../modals/PaymentResultModal'
import { useRequest } from '../../../hooks/useRequest'
import { usePostUserLogOutAPI } from '../../../api/auth/postUserLogOut'
import { IconButton } from '../../../atoms/IconButton'

export const TopNavigationMenu = () => {
  const request = useRequest()

  const [open, setOpen] = useState(false)

  const { isLoading, getUser, user } = useGetCurrentUserAPI()

  const { onOpenEmailSend, sendEmailModalProps } = useSendEmailModal(user)

  const { verifyEmailModalProps } = useVerifyEmailModal()

  const { verifyPasswordRecoveryModalProps } = useVerifyPasswordRecoveryModal()

  const {
    handleOpenSmsVerificationModal,
    handleOpenSmsVerificationModalWithPhone,
    verifyPhoneModalProps
  } = useVerifyPhoneModal()

  const { userRegistrationModalProps, onOpenUserRegistration } =
    useUserRegistrationModal(
      onOpenEmailSend,
      handleOpenSmsVerificationModalWithPhone
    )

  const { userLoginModalProps, onOpenUserLogin } = useUserLoginModal(
    onOpenUserRegistration
  )

  const { paymentResultModalProps } = usePaymentResultModal()

  const { isLoading: isLogoutLoading, userLogout } = usePostUserLogOutAPI({
    onSuccess: () => {
      request.logout()
      getUser()
    }
  })

  useEffect(() => {
    getUser()
  }, [])

  const actionMenuLabel = useMemo(() => {
    if (user) {
      const { firstName, lastName } = user
      return `${firstName} ${lastName}`
    }
    return ''
  }, [user])

  const actionMenuConfig = useMemo(() => {
    const actionConfig = []

    if (!(user?.emailVerified || user?.smsVerified)) {
      actionConfig.push({
        label: 'Email Verification',
        onClick: onOpenEmailSend
      })
      if (user?.country === 'GE') {
        actionConfig.push({
          label: 'Phone Verification',
          onClick: handleOpenSmsVerificationModal
        })
      }
    }

    actionConfig.push({
      label: 'Log Out',
      onClick: () => userLogout()
    })

    return actionConfig
  }, [user])

  const toggleDrawer = (_open: boolean) => () => {
    setOpen(_open)
  }

  return (
    <AppBar position='static' elevation={10}>
      <Grid
        container
        alignItems='center'
        justifyContent='space-between'
        flexWrap='nowrap'
        sx={{
          padding: 1
        }}
      >
        <Grid item>
          <IFCLogo />
        </Grid>
        <Show
          when={user}
          fallback={
            <>
              <Grid
                item
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'flex'
                  },
                  gap: 2,
                  alignItems: 'center'
                }}
              >
                <OutlinedButton
                  onClick={onOpenUserRegistration}
                  disabled={isLoading || isLogoutLoading}
                >
                  <MainTypography>REGISTRATION</MainTypography>
                </OutlinedButton>
                <OutlinedButton
                  onClick={onOpenUserLogin}
                  disabled={isLoading || isLogoutLoading}
                >
                  <MainTypography>SIGN IN</MainTypography>
                </OutlinedButton>
              </Grid>
              <Grid
                item
                sx={{
                  display: {
                    xs: 'flex',
                    sm: 'flex',
                    md: 'none'
                  }
                }}
              >
                <IconButton
                  onClick={toggleDrawer(true)}
                  sx={{ backgroundColor: 'transparent' }}
                >
                  <MenuIcon htmlColor='white' />
                </IconButton>
              </Grid>
            </>
          }
        >
          <>
            <Grid
              item
              sx={{
                display: {
                  xs: 'none',
                  sm: 'none',
                  md: 'flex'
                },
                gap: 2,
                alignItems: 'center'
              }}
            >
              <ActionMenu label={actionMenuLabel} actions={actionMenuConfig} />
            </Grid>
            <Grid
              item
              sx={{
                display: {
                  xs: 'flex',
                  sm: 'flex',
                  md: 'none'
                }
              }}
            >
              <IconButton
                onClick={toggleDrawer(true)}
                sx={{ backgroundColor: 'transparent' }}
              >
                <MenuIcon htmlColor='white' />
              </IconButton>
            </Grid>
          </>
        </Show>
      </Grid>
      <Drawer
        anchor='right'
        open={open}
        variant='persistent'
        onClose={toggleDrawer(open)}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: 'transparent'
          }
        }}
      >
        <Stack
          sx={{
            padding: '20px 15px',
            backgroundColor: '#000000BF',
            height: '100vh'
          }}
        >
          <Grid container justifyContent='flex-start'>
            <Grid item xs={6}>
              <IconButton
                onClick={toggleDrawer(false)}
                sx={{ mb: 2, backgroundColor: 'transparent' }}
              >
                <CloseIcon htmlColor='white' />
              </IconButton>
            </Grid>
          </Grid>
          <Show
            when={user}
            fallback={
              <Grid
                item
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  alignItems: 'center'
                }}
              >
                <OutlinedButton
                  onClick={onOpenUserRegistration}
                  disabled={isLoading || isLogoutLoading}
                >
                  <MainTypography>REGISTRATION</MainTypography>
                </OutlinedButton>
                <OutlinedButton
                  onClick={onOpenUserLogin}
                  disabled={isLoading || isLogoutLoading}
                >
                  <MainTypography>SIGN IN</MainTypography>
                </OutlinedButton>
              </Grid>
            }
          >
            <Grid
              item
              sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center'
              }}
            >
              <ActionMenu label={actionMenuLabel} actions={actionMenuConfig} />
            </Grid>
          </Show>
        </Stack>
      </Drawer>
      <UserRegistrationModal {...userRegistrationModalProps} />
      <UserLoginModal {...userLoginModalProps} />
      <SendEmailModal {...sendEmailModalProps} />
      <VerifyEmailModal {...verifyEmailModalProps} />
      <VerifyPasswordRecoveryModal {...verifyPasswordRecoveryModalProps} />
      <VerifyPhoneModal {...verifyPhoneModalProps} />
      <PaymentResultModal {...paymentResultModalProps} />
    </AppBar>
  )
}
