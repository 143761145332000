import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { routes } from '../../../routes'
import { usePostCheckPaymentAPI } from '../../../api/payment/postCheckPayment'

export const usePaymentResultModal = () => {
  const { paymentId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const [isOpen, setOpen] = useState(false)
  const [isSuccessfulPayment, setIsSuccessfulPayment] = useState(false)

  const { isLoading, checkPayment } = usePostCheckPaymentAPI({
    onSuccess: ({ data }) => {
      setIsSuccessfulPayment(data?.status)
    },
    onError: () => {
      setIsSuccessfulPayment(false)
    }
  })

  const onOpen = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const onCancel = useCallback(() => {
    setOpen(false)
    navigate(routes.home)
    window.location.reload()
  }, [setOpen])

  const onOpenHandler = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  useEffect(() => {
    const successWithoutParams = routes.paymentSuccess.split('/')[0]
    const failWithoutParams = routes.paymentFail.split('/')[0]

    if (
      location.pathname.includes(successWithoutParams) ||
      location.pathname.includes(failWithoutParams)
    ) {
      checkPayment({ paymentId: Number(paymentId) })
      onOpenHandler()
    }
  }, [])

  return {
    paymentResultModalProps: {
      isLoading,
      isOpen,
      setOpen,
      onCancel,
      onOpen,
      isSuccessfulPayment
    }
  }
}
