import { useQuery } from 'react-query'

import { useRequest } from '../../../hooks/useRequest'
import { QUERY_CACHE_KEYS } from '../../cacheKeys'
import { QueryOptions } from '../../types'

import {
  GetEventErrorResponse,
  getEvent,
  GetEventSuccessResponse
} from './getCurrentEvent'

export const useGetCurrentEventAPI = (
  options: QueryOptions<GetEventSuccessResponse, GetEventErrorResponse> = {}
) => {
  const requestContext = useRequest()

  const { isLoading, isRefetching, data, refetch, error } = useQuery<
    GetEventSuccessResponse,
    GetEventErrorResponse
  >(QUERY_CACHE_KEYS.event, getEvent(requestContext), {
    enabled: true,
    ...options
  })

  return {
    isLoading: isLoading || isRefetching,
    getEvent: refetch,
    event: data?.data?.data,
    error
  }
}
