import {
  FC,
  PropsWithChildren,
  createContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import axios, { Axios, AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'

import { localStorageKeys } from '../../constants/localStorageKeys'
import { TokenType } from '../../api/security/getToken'
import { routes } from '../../routes'

export interface RequestStoreContextState {
  request: Axios
  sessionResponse?: TokenType
  setSession: (sessionResponse: TokenType) => void
  setHeader: (key: string, value: string) => void
  logout: VoidFunction
}

const request = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json'
  }
})

export const RequestStoreContext = createContext({} as RequestStoreContextState)

const session = localStorage.getItem(localStorageKeys.IFCsession)

export const RequestStoreContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const [currentSessionResponse, setSessionResponse] = useState<
    TokenType | undefined
  >(session ? JSON.parse(session) : undefined)

  const navigate = useNavigate()

  const setHeader = (key: string, value: string) => {
    request.defaults.headers.common[key] = value
  }

  const setSession = (sessionResponse: TokenType) => {
    setSessionResponse(sessionResponse)

    setHeader('Authorization', `Bearer ${sessionResponse.token.accessToken}`)

    localStorage.setItem(
      localStorageKeys.IFCsession,
      JSON.stringify(sessionResponse)
    )
  }

  const logout = () => {
    setSessionResponse(undefined)
    request.defaults.headers.common.Authorization = undefined
    localStorage.removeItem(localStorageKeys.IFCsession)
    navigate(routes.home)
    window.location.reload()
  }

  useEffect(() => {
    if (currentSessionResponse) {
      setHeader(
        'Authorization',
        `Bearer ${currentSessionResponse.token.accessToken}`
      )
    }

    request.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        // if (error.response?.status === 401) {
        //   if (
        //     error.config?.url === apiRoutes.validateToken ||
        //     error.config?.url === apiRoutes.getEmail
        //   ) {
        //     return Promise.resolve()
        //   }

        //   // logout()
        // }

        return Promise.reject(error)
      }
    )
  }, [])

  const value = useMemo(
    () => ({
      request,
      logout,
      setHeader,
      setSession,
      sessionResponse: currentSessionResponse
    }),
    [currentSessionResponse]
  )

  return (
    <RequestStoreContext.Provider value={value}>
      {children}
    </RequestStoreContext.Provider>
  )
}
