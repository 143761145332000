import { generatePath } from 'react-router-dom'

import {
  ErrorResponse,
  MutationRequestFunction,
  SuccessResponse
} from '../../types'
import { apiRoutes } from '../../apiRoutes'
import { ErrorType } from '../../commonTypes'

import { FreeEventPaymentResponse } from './types'

export type FreeEventPaymentProps = {
  eventId: number
}

export type FreeEventPaymentSuccessResponse =
  SuccessResponse<FreeEventPaymentResponse>
export type FreeEventPaymentErrorResponse = ErrorResponse<ErrorType>

export const freeEventPayment: MutationRequestFunction<
  FreeEventPaymentSuccessResponse,
  FreeEventPaymentProps
> =
  ({ request }) =>
  async ({ eventId }) =>
    request.post(
      generatePath(apiRoutes.postFreeEventPayment, {
        eventId: String(eventId)
      })
    )
