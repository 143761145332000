import { generatePath } from 'react-router-dom'

import {
  ErrorResponse,
  QueryRequestFunction,
  SuccessResponse
} from '../../types'
import { apiRoutes } from '../../apiRoutes'
import { ErrorType } from '../../commonTypes'

import { GetEventResponse } from './types'

export type GetEventSuccessResponse = SuccessResponse<GetEventResponse>
export type GetEventErrorResponse = ErrorResponse<ErrorType>

export const getEvent: QueryRequestFunction<GetEventSuccessResponse> =
  ({ request }) =>
  async () => {
    return request.get(generatePath(apiRoutes.getCurrentEvent))
  }
