import { FC, PropsWithChildren } from 'react'
import { Grid, CircularProgress, styled, GridProps } from '@mui/material'
import { Show } from 'react-extend-jsx'

const ProgressIcon = styled(CircularProgress)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
}))

const SpinnerContainer = styled(Grid)(() => ({
  position: 'relative',
  pointerEvents: 'none'
}))

const ChildContainer = styled(Grid)(() => ({
  filter: 'blur(5px)'
}))

export type LoadableContainerProps = PropsWithChildren<{
  loading?: boolean
  sx?: GridProps['sx']
}>

export const LoadableContainer: FC<LoadableContainerProps> = ({
  loading,
  children,
  sx
}) => {
  return (
    <Show
      when={!loading}
      fallback={
        <SpinnerContainer>
          <ChildContainer sx={sx}>{children}</ChildContainer>
          <ProgressIcon />
        </SpinnerContainer>
      }
    >
      <Grid>{children}</Grid>
    </Show>
  )
}
