import { useCallback, useState } from 'react'

import { useNotification } from '../../../atoms/Notification'
import { useGetCurrentEventAPI } from '../../../api/users/getCurrentEvent'
import { usePostProcessPaymentAPI } from '../../../api/payment/postProcessPayment'

export const usePaymentModal = () => {
  const { openErrorNotification } = useNotification()

  const { event } = useGetCurrentEventAPI()

  const [isOpen, setOpen] = useState(false)

  const onOpen = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const onCancel = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const onOpenHandler = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const { isLoading, processPayment } = usePostProcessPaymentAPI({
    onSuccess: ({ data }) => {
      if (data?.paymentUrl) {
        window.location.href = data.paymentUrl
      }
    },
    onError: () => {
      openErrorNotification('Payment Attempt Failed')
    }
  })

  const onOpenPayment = () => {
    onOpenHandler()
  }

  const onSubmit = () => {
    if (event) {
      processPayment({ eventId: event?.id })
    }
  }

  return {
    onOpenPayment,
    processPatmentModalProps: {
      isOpen,
      setOpen,
      handleSubmit: onSubmit,
      onCancel,
      onOpen,
      isLoading
    }
  }
}
