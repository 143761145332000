import { FC, useMemo } from 'react'
import { Stack } from '@mui/material'

import { ActionModal } from '../../../atoms/Modal'
import { MainTypography } from '../../../atoms/Typography'
import { FilledButton } from '../../../atoms/FilledButton'

import { SendEmailModalProps } from './types'

export const SendEmailModal: FC<SendEmailModalProps> = ({
  isOpen,
  setOpen,
  handleSubmit,
  isLoading,
  userInfo
}) => {
  const handleSubmitForm = async () => {
    await handleSubmit()
  }

  const emailAddress = useMemo(() => {
    return userInfo?.email || ''
  }, [userInfo])

  return (
    <ActionModal
      isOpen={isOpen}
      setOpen={() => {
        setOpen(false)
      }}
    >
      <Stack
        sx={{
          width: '100%',
          marginBottom: 3,
          textAlign: 'center'
        }}
      >
        <MainTypography
          sx={{
            color: 'black'
          }}
        >
          Email Verification
        </MainTypography>
      </Stack>
      <Stack rowGap={3}>
        <MainTypography
          sx={{
            color: 'black',
            fontSize: '18px'
          }}
        >
          An Email will be sent to your email address to verify your account
        </MainTypography>
        <MainTypography
          sx={{
            color: 'black',
            textAlign: 'center'
          }}
        >
          {emailAddress}
        </MainTypography>

        <Stack
          rowGap={2}
          sx={{
            width: '70%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 'auto'
          }}
        >
          <FilledButton disabled={isLoading} onClick={handleSubmitForm}>
            Send Email
          </FilledButton>
        </Stack>
      </Stack>
    </ActionModal>
  )
}
