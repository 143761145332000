import { ActionModalProps } from '../../../atoms/Modal'

export enum IVerifyPasswordRecoveryFields {
  PASSWORD = 'password'
}

export interface IVerifyPasswordRecoveryFormValues {
  [IVerifyPasswordRecoveryFields.PASSWORD]: string
}

export const initialValues: IVerifyPasswordRecoveryFormValues = {
  [IVerifyPasswordRecoveryFields.PASSWORD]: ''
}

export type VerifyPasswordRecoveryModalProps = Pick<
  ActionModalProps,
  'isOpen' | 'setOpen'
> & {
  isLoading: boolean
  setOpen: (value: boolean) => void
  handleSubmit: (values: IVerifyPasswordRecoveryFormValues) => void
  initialValues: IVerifyPasswordRecoveryFormValues
}
