import { generatePath } from 'react-router-dom'

import {
  ErrorResponse,
  MutationRequestFunction,
  SuccessResponse
} from '../../types'
import { apiRoutes } from '../../apiRoutes'
import { ErrorType } from '../../commonTypes'

import { UserRegistrationResponse } from './types'

export type UserRegistrationProps = {
  firstName: string
  lastName: string
  email: string
  password: string
  phoneNumber: string
  citizenship: string
}

export type UserRegistrationSuccessResponse =
  SuccessResponse<UserRegistrationResponse>
export type UserRegistrationErrorResponse = ErrorResponse<ErrorType>

export const userRegistration: MutationRequestFunction<
  UserRegistrationSuccessResponse,
  UserRegistrationProps
> =
  ({ request }) =>
  async ({ ...user }) =>
    request.post(generatePath(apiRoutes.postUserRegistration), user)
