import { Grid } from '@mui/material'

import { IFCLogo } from '../../../assets/icons'
import { MainTypography } from '../../../atoms/Typography'

export const HomePageFooter = () => {
  return (
    <Grid
      container
      flexDirection='row'
      alignItems='center'
      justifyContent='center'
      gap={3}
      sx={{
        marginTop: 15
      }}
    >
      <Grid
        item
        lg={3}
        md={5}
        xs={12}
        sm={12}
        sx={{
          textAlign: 'center'
        }}
      >
        <IFCLogo />
      </Grid>
      <Grid
        item
        lg={3}
        md={5}
        xs={12}
        sm={12}
        sx={{
          textAlign: 'center'
        }}
      >
        <MainTypography>INTEGRA FIGHTING CHAMPIONSHIP</MainTypography>
      </Grid>
      <Grid
        item
        lg={3}
        md={5}
        xs={12}
        sm={12}
        sx={{
          textAlign: 'center'
        }}
      >
        <MainTypography
          sx={{
            fontWeight: '100'
          }}
        >
          All Rights Reserved © 2024
        </MainTypography>
      </Grid>
    </Grid>
  )
}
