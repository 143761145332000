import { FC, useRef } from 'react'
import { Stack } from '@mui/material'
import { Form, Formik, FormikConfig, FormikProps } from 'formik'

import { ActionModal } from '../../../atoms/Modal'
import { MainTypography } from '../../../atoms/Typography'
import { FilledButton } from '../../../atoms/FilledButton'
import { TextInputField } from '../../../atoms/TextInputField'

import {
  IVerifyPasswordRecoveryFields,
  IVerifyPasswordRecoveryFormValues,
  VerifyPasswordRecoveryModalProps
} from './types'
import { passwordRecoverySchema } from './loginUserValidation'

export const VerifyPasswordRecoveryModal: FC<
  VerifyPasswordRecoveryModalProps
> = ({ isOpen, setOpen, isLoading, initialValues, handleSubmit }) => {
  const formRef = useRef<FormikProps<typeof initialValues>>(null)

  const handleSubmitForm: FormikConfig<IVerifyPasswordRecoveryFormValues>['onSubmit'] =
    async (values) => {
      await handleSubmit(values)
    }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <ActionModal isOpen={isOpen} setOpen={handleClose}>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange
        validationSchema={passwordRecoverySchema}
        onSubmit={handleSubmitForm}
      >
        <Form>
          <Stack
            sx={{
              width: '100%',
              marginBottom: 3,
              textAlign: 'center'
            }}
          >
            <MainTypography
              sx={{
                color: 'black'
              }}
            >
              Password Recovery
            </MainTypography>
          </Stack>
          <Stack rowGap={3}>
            <TextInputField
              name={IVerifyPasswordRecoveryFields.PASSWORD}
              label='Password'
              type='password'
            />
            <Stack
              rowGap={2}
              sx={{
                width: '70%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: 'auto'
              }}
            >
              <FilledButton
                disabled={isLoading}
                onClick={() => formRef.current?.submitForm()}
              >
                Submit
              </FilledButton>
            </Stack>
          </Stack>
        </Form>
      </Formik>
    </ActionModal>
  )
}
