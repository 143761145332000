import { generatePath } from 'react-router-dom'

import {
  ErrorResponse,
  MutationRequestFunction,
  SuccessResponse
} from '../../types'
import { apiRoutes } from '../../apiRoutes'
import { ErrorType } from '../../commonTypes'

import { UserLoginResponse } from './types'

export type UserLoginProps = {
  email: string
  password: string
}

export type UserLoginSuccessResponse = SuccessResponse<UserLoginResponse>
export type UserLoginErrorResponse = ErrorResponse<ErrorType>

export const userLogin: MutationRequestFunction<
  UserLoginSuccessResponse,
  UserLoginProps
> =
  ({ request }) =>
  async ({ ...user }) =>
    request.post(generatePath(apiRoutes.postUserLogin), user)
