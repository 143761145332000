import { MutationOptions, useMutation } from 'react-query'

import { useRequest } from '../../../hooks/useRequest'

import {
  processPayment,
  ProcessPaymentErrorResponse,
  ProcessPaymentProps,
  ProcessPaymentSuccessResponse
} from './processPayment'

export const usePostProcessPaymentAPI = (
  options: MutationOptions<
    ProcessPaymentSuccessResponse,
    ProcessPaymentErrorResponse,
    ProcessPaymentProps
  > = {}
) => {
  const requestContext = useRequest()

  const { isLoading, mutate } = useMutation<
    ProcessPaymentSuccessResponse,
    ProcessPaymentErrorResponse,
    ProcessPaymentProps
  >(processPayment(requestContext), options)

  return {
    isLoading,
    processPayment: mutate
  }
}
