import { Button, buttonClasses, styled } from '@mui/material'
import { FC } from 'react'

import { MainTypography } from '../../atoms/Typography'

const StyledButton = styled(Button)(({ theme }) => ({
  height: 48,
  ...theme.typography.labelLarge,
  textTransform: 'none',
  padding: '12px 18px',
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 2px 0px 0px rgba(235, 56, 38, 1)',
  borderRadius: '12px',
  color: 'black',
  [`&.${buttonClasses.disabled}`]: {
    backgroundColor: theme.palette.neutral.light,
    color: theme.palette.neutral.medium
  },
  [`&.${buttonClasses.focusVisible}`]: {
    backgroundColor: theme.palette.primary.dark
  },
  '&:hover': {
    backgroundColor: '#FFFFFF80',
    boxShadow: '0px 2px 0px 0px rgba(235, 56, 38, 1)',
    color: 'black'
  }
}))

interface HomePageButtonProps {
  label: string
  disabled: boolean
  onClick: () => void
}

export const HomePageButton: FC<HomePageButtonProps> = ({
  label,
  disabled = false,
  onClick
}) => {
  return (
    <StyledButton onClick={onClick} disabled={disabled}>
      <MainTypography
        sx={{
          color: '#000000'
        }}
      >
        {label}
      </MainTypography>
    </StyledButton>
  )
}
