import { createTheme, ThemeOptions } from '@mui/material/styles'

import SofiaSansCondensed from '../assets/fonts/SofiaSansCondensed-VariableFont_wght.ttf'

import { typography } from './typography'
import { colors } from './colors'

const themeOptions: ThemeOptions = {
  alert: colors.alert,
  success: colors.success,
  warning: colors.warning,
  info: colors.info,
  temperature: colors.temperature,
  status: colors.status,
  badge: colors.badge,
  common: colors.common,
  typography,
  palette: {
    background: {
      default: colors.primary.lightest
    },
    primary: colors.primary,
    secondary: colors.secondary,
    neutral: colors.neutral,
    surface: colors.surface,
    alert: colors.alert,
    temperature: colors.temperature,
    temperatureLineChart: colors.temperatureLineChart,
    badge: colors.badge
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Open Sans';
          src: url(${SofiaSansCondensed}) format('ttf');
          font-weight: normal;
          font-style: normal;
        },
        body {
          scrollbar-color: #c2c2c2 transparent
        }
      `
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          ...typography.bodyLarge
        }
      }
    },
    MuiInputLabel: {
      defaultProps: {
        required: false
      },
      styleOverrides: {
        root: {
          ...typography.bodyLarge,
          color: colors.neutral.mediumDark,
          '&.Mui-disabled': {
            color: colors.neutral.mediumDark
          },
          '&.Mui-focused': {
            color: colors.primary.main
          },
          '&.Mui-error': {
            color: colors.neutral.mediumDark
          }
        },
        shrink: {
          '&.Mui-error': {
            color: colors.alert.high
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          ...typography.bodySmall,
          color: colors.primary.darkest,
          '&.Mui-error': {
            color: colors.alert.high
          },
          '&.Mui-disabled': {
            color: colors.neutral.medium
          }
        }
      }
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true
      }
    },
    MuiButton: {
      defaultProps: {
        disableFocusRipple: true,
        disableRipple: true,
        disableElevation: true,
        fullWidth: true
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ...typography.bodyLarge,
          color: colors.neutral.extraDark
        }
      }
    },
    MuiFormControlLabel: {
      defaultProps: {
        slotProps: {
          typography: {
            variant: 'bodyMedium',
            color: 'neutral.darkest'
          }
        }
      }
    },
    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: {
          variant: 'bodyMedium',
          color: 'neutral.extraDark'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            fontFamily: typography.fontFamily
          }
        }
      }
    }
  },
  idionShadows: {
    menu: '3px 3px 6px rgba(0, 0, 0, 0.08)',
    button:
      '0px 1px 3px rgba(0, 0, 0, 0.15), 0px 1px 4px 1px rgba(0, 0, 0, 0.1)',
    filters: '1px 3px 6px rgba(0, 0, 0, 0.08)',
    formContainer: '4px 2px 20px rgba(0, 0, 0, 0.06)',
    stickyColumn: '4px 0px rgba(0, 0, 0, 0.06)'
  }
}
const theme = createTheme(themeOptions)
export { theme }
