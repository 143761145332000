import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { routes } from '../../routes'
import { RequestStoreContextProvider } from '../../providers/requestStore'
import { HomePage } from '../../routes/NoAuth/HomePage/HomePage'
import { RefreshTokenController } from '../../providers/refreshTokenProvider'

export const ApplicationRouter: FC = () => {
  return (
    <RequestStoreContextProvider>
      <RefreshTokenController />

      <Routes>
        <Route path={routes.home} element={<HomePage />} />
        <Route path={routes.emailVerificaiton} element={<HomePage />} />
        <Route path={routes.passwordRecovery} element={<HomePage />} />
        <Route path={routes.paymentSuccess} element={<HomePage />} />
        <Route path={routes.paymentFail} element={<HomePage />} />

        <Route path='*' element={<Navigate to={routes.home} />} />
      </Routes>
    </RequestStoreContextProvider>
  )
}
