import { QueryClientConfig } from 'react-query'

export const queryConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1
    }
  }
}

export const notificationSettings = {
  vertical: 'top',
  horizontal: 'right'
} as const
