import { MutationOptions, useMutation } from 'react-query'

import { useRequest } from '../../../hooks/useRequest'

import {
  userRegistration,
  UserRegistrationErrorResponse,
  UserRegistrationProps,
  UserRegistrationSuccessResponse
} from './userRegistration'

export const useUserRegistrationAPI = (
  options: MutationOptions<
    UserRegistrationSuccessResponse,
    UserRegistrationErrorResponse,
    UserRegistrationProps
  > = {}
) => {
  const requestContext = useRequest()

  const { isLoading, mutate } = useMutation<
    UserRegistrationSuccessResponse,
    UserRegistrationErrorResponse,
    UserRegistrationProps
  >(userRegistration(requestContext), options)

  return {
    isLoading,
    userRegistration: mutate
  }
}
