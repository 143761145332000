import { ActionModalProps } from '../../../atoms/Modal'

export enum IUserRegistrationFormFields {
  NAME = 'name',
  LASTNAME = 'lastname',
  PHONE = 'phone',
  EMAIL = 'email',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword'
}

export interface IPostUserRegistrationFormValues {
  [IUserRegistrationFormFields.NAME]: string
  [IUserRegistrationFormFields.LASTNAME]: string
  [IUserRegistrationFormFields.PHONE]: string
  [IUserRegistrationFormFields.EMAIL]: string
  [IUserRegistrationFormFields.PASSWORD]: string
  [IUserRegistrationFormFields.CONFIRM_PASSWORD]: string
}

export const initialValues: IPostUserRegistrationFormValues = {
  [IUserRegistrationFormFields.NAME]: '',
  [IUserRegistrationFormFields.LASTNAME]: '',
  [IUserRegistrationFormFields.PHONE]: '',
  [IUserRegistrationFormFields.EMAIL]: '',
  [IUserRegistrationFormFields.PASSWORD]: '',
  [IUserRegistrationFormFields.CONFIRM_PASSWORD]: ''
}

export type UserRegistrationModalProps = Pick<
  ActionModalProps,
  'isOpen' | 'setOpen'
> & {
  isLoading: boolean
  setOpen: (value: boolean) => void
  handleSubmit: (values: IPostUserRegistrationFormValues) => void
}
