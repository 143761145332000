import { FC, useRef } from 'react'
import { Form, Formik, FormikConfig, FormikProps } from 'formik'
import { Stack } from '@mui/material'

import { TextInputField } from '../../../atoms/TextInputField'
import { ActionModal } from '../../../atoms/Modal'
import { MainTypography } from '../../../atoms/Typography'

import { registerUserSchema } from './registerUserValidation'
import {
  IPostUserRegistrationFormValues,
  IUserRegistrationFormFields,
  UserRegistrationModalProps,
  initialValues
} from './types'

export const UserRegistrationModal: FC<UserRegistrationModalProps> = ({
  isOpen,
  setOpen,
  handleSubmit,
  isLoading
}) => {
  const formRef = useRef<FormikProps<typeof initialValues>>(null)

  const handleSubmitForm: FormikConfig<IPostUserRegistrationFormValues>['onSubmit'] =
    async (values) => {
      await handleSubmit(values)
    }

  return (
    <ActionModal
      isOpen={isOpen}
      setOpen={() => {
        setOpen(false)
      }}
      submit={{
        text: 'Register',
        callback: () => {
          formRef.current?.submitForm()
        },
        disabled: isLoading
      }}
      onClose={() => setOpen(false)}
      buttonsPosition='center'
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange
        validationSchema={registerUserSchema}
        onSubmit={handleSubmitForm}
      >
        <Form>
          <Stack
            sx={{
              width: '100%',
              marginBottom: 3,
              textAlign: 'center'
            }}
          >
            <MainTypography
              sx={{
                color: 'black'
              }}
            >
              Sign Up
            </MainTypography>
          </Stack>
          <Stack rowGap={3}>
            <Stack
              flexDirection='row'
              justifyContent='space-between'
              alignContent='center'
              gap={1}
            >
              <TextInputField
                name={IUserRegistrationFormFields.NAME}
                label='Name'
              />
              <TextInputField
                name={IUserRegistrationFormFields.LASTNAME}
                label='Last Name'
              />
            </Stack>
            <TextInputField
              name={IUserRegistrationFormFields.EMAIL}
              label='Email'
            />
            <TextInputField
              name={IUserRegistrationFormFields.PHONE}
              label='Phone Number'
            />
            <TextInputField
              name={IUserRegistrationFormFields.PASSWORD}
              label='Enter Password'
              type='password'
            />
            <TextInputField
              name={IUserRegistrationFormFields.CONFIRM_PASSWORD}
              label='Re-Enter Password'
              type='password'
            />
          </Stack>
        </Form>
      </Formik>
    </ActionModal>
  )
}
