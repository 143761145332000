import { MutationOptions, useMutation } from 'react-query'

import { useRequest } from '../../../hooks/useRequest'

import {
  userLogin,
  UserLoginErrorResponse,
  UserLoginProps,
  UserLoginSuccessResponse
} from './userLogin'

export const useUserLoginAPI = (
  options: MutationOptions<
    UserLoginSuccessResponse,
    UserLoginErrorResponse,
    UserLoginProps
  > = {}
) => {
  const requestContext = useRequest()

  const { isLoading, mutate } = useMutation<
    UserLoginSuccessResponse,
    UserLoginErrorResponse,
    UserLoginProps
  >(userLogin(requestContext), options)

  return {
    isLoading,
    userLogin: mutate
  }
}
