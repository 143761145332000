import { FC } from 'react'
import { Stack } from '@mui/material'

import { ActionModal } from '../../../atoms/Modal'
import { MainTypography } from '../../../atoms/Typography'
import { FilledButton } from '../../../atoms/FilledButton'

import { VerifyEmailModalProps } from './types'

export const VerifyEmailModal: FC<VerifyEmailModalProps> = ({
  isOpen,
  onCancel,
  isLoading,
  description
}) => {
  const handleClose = () => {
    onCancel()
  }

  return (
    <ActionModal isOpen={isOpen} setOpen={handleClose}>
      <Stack
        sx={{
          width: '100%',
          marginBottom: 3,
          textAlign: 'center'
        }}
      >
        <MainTypography
          sx={{
            color: 'black'
          }}
        >
          Email Verification
        </MainTypography>
      </Stack>
      <Stack rowGap={3}>
        <MainTypography
          sx={{
            color: 'black',
            fontSize: '18px',
            textAlign: 'center'
          }}
        >
          {description}
        </MainTypography>

        <Stack
          rowGap={2}
          sx={{
            width: '70%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 'auto'
          }}
        >
          <FilledButton disabled={isLoading} onClick={handleClose}>
            Close
          </FilledButton>
        </Stack>
      </Stack>
    </ActionModal>
  )
}
