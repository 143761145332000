import { useCallback, useMemo } from 'react'
import { Box, Stack } from '@mui/material'
import dayjs from 'dayjs'

import { IFCLogoSmall, LeaderbetLogo } from '../../../assets/icons'
import { MainTypography } from '../../../atoms/Typography'
import { TimeLeftTimer } from '../../../molecules/TimeLeftTimer'
import { HomePageButton } from '../../../molecules/HomePageButton'
import { StreamModal, useStreamModal } from '../../modals/StreamModal'
import { useGetCurrentUserAPI } from '../../../api/users/getCurrentUser'
import { useGetCurrentEventAPI } from '../../../api/users/getCurrentEvent'
import { PaymentModal, usePaymentModal } from '../../modals/PaymentModal'
import { UserLoginModal, useUserLoginModal } from '../../modals/UserLoginModal'
import {
  UserRegistrationModal,
  useUserRegistrationModal
} from '../../modals/UserRegistrationModal'
import { SendEmailModal, useSendEmailModal } from '../../modals/SendEmailModal'
import {
  useVerifyPasswordRecoveryModal,
  VerifyPasswordRecoveryModal
} from '../../modals/VerifyPasswordRecoveryModal'
import {
  useVerifyPhoneModal,
  VerifyPhoneModal
} from '../../modals/VerifyPhoneModal'
import { usePostFreeEventPaymentAPI } from '../../../api/payment/postFreeEventPayment'
import { LoadableContainer } from '../../../layout/LoadableContainer'

export const EventInfoWidget = () => {
  const { user } = useGetCurrentUserAPI()
  const { event } = useGetCurrentEventAPI()
  const { streamModalModalProps, onOpenStreamModal } = useStreamModal()
  const { processPatmentModalProps, onOpenPayment } = usePaymentModal()

  const { onOpenEmailSend, sendEmailModalProps } = useSendEmailModal(user)

  const { verifyPasswordRecoveryModalProps } = useVerifyPasswordRecoveryModal()

  const {
    handleOpenSmsVerificationModal,
    handleOpenSmsVerificationModalWithPhone,
    verifyPhoneModalProps
  } = useVerifyPhoneModal()
  const { onOpenUserRegistration, userRegistrationModalProps } =
    useUserRegistrationModal(
      onOpenEmailSend,
      handleOpenSmsVerificationModalWithPhone
    )
  const { userLoginModalProps, onOpenUserLogin } = useUserLoginModal(
    onOpenUserRegistration
  )

  const { isLoading: isFreePaymentLoading, freeEventPayment } =
    usePostFreeEventPaymentAPI({
      onSuccess: () => {
        onOpenStreamModal()
      }
    })

  const handleWatchButtonClick = useCallback(() => {
    if (!user) {
      onOpenUserLogin()
      return
    }
    if (!(user.emailVerified || user.smsVerified)) {
      if (user.country === 'GE') {
        handleOpenSmsVerificationModal()
      } else {
        onOpenEmailSend()
      }
      return
    }

    const eventIsPaid = user?.events?.find(
      (userEvent) => userEvent.id === event?.id
    )

    if (eventIsPaid) {
      onOpenStreamModal()
      return
    }
    if (user.country === 'GE') {
      freeEventPayment({ eventId: event!.id })
      return
    }
    onOpenPayment()
  }, [user])

  const streamButtonLabel = useMemo(() => {
    const eventIsPaid = user?.events?.find(
      (userEvent) => userEvent.id === event?.id
    )

    const watchOnline = eventIsPaid || user?.country === 'GE'
    return watchOnline ? 'WATCH ONLINE' : 'BUY STREAM'
  }, [user])

  return (
    <Box
      sx={{
        md: {
          minWidth: '25vw'
        },
        xs: {
          minWidth: '100vw'
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 6
      }}
    >
      <Box>
        <IFCLogoSmall />
      </Box>
      <Box
        sx={{
          md: {
            minWidth: '25vw'
          },
          xs: {
            minWidth: '100vw'
          },
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 3
        }}
      >
        <MainTypography
          variant='headlineLarge'
          sx={{
            fontSize: '1.5rem'
          }}
        >
          TBILISI SPORTS PALACE
        </MainTypography>
        <MainTypography
          variant='headlineLarge'
          sx={{
            fontSize: '1.53rem'
          }}
        >
          07.12.2024
        </MainTypography>
        <MainTypography
          variant='headlineLarge'
          sx={{
            fontSize: '1.25rem'
          }}
        >
          19:00
        </MainTypography>
      </Box>
      <Stack>
        <LeaderbetLogo />
      </Stack>
      <Stack>
        <LoadableContainer loading={isFreePaymentLoading}>
          <HomePageButton
            onClick={handleWatchButtonClick}
            label={streamButtonLabel}
            disabled={isFreePaymentLoading || !event}
          />
        </LoadableContainer>
      </Stack>
      <Stack>
        <TimeLeftTimer
          eventDate={dayjs(new Date(2024, 11, 7, 19, 0)).toISOString()}
        />
      </Stack>

      <StreamModal {...streamModalModalProps} />
      <PaymentModal {...processPatmentModalProps} />
      <UserLoginModal {...userLoginModalProps} />
      <UserRegistrationModal {...userRegistrationModalProps} />
      <SendEmailModal {...sendEmailModalProps} />
      <VerifyPhoneModal {...verifyPhoneModalProps} />
      <VerifyPasswordRecoveryModal {...verifyPasswordRecoveryModalProps} />
    </Box>
  )
}
