import { generatePath } from 'react-router-dom'

import {
  ErrorResponse,
  MutationRequestFunction,
  SuccessResponse
} from '../../types'
import { apiRoutes } from '../../apiRoutes'
import { ErrorType } from '../../commonTypes'

import { CheckPaymentResponse } from './types'

export type CheckPaymentProps = {
  paymentId: number
}

export type CheckPaymentSuccessResponse = SuccessResponse<CheckPaymentResponse>
export type CheckPaymentErrorResponse = ErrorResponse<ErrorType>

export const checkPayment: MutationRequestFunction<
  CheckPaymentSuccessResponse,
  CheckPaymentProps
> =
  ({ request }) =>
  async ({ paymentId }) =>
    request.post(
      generatePath(apiRoutes.postCheckPayment, {
        paymentId: String(paymentId)
      })
    )
