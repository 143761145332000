import { useMutation } from 'react-query'

import { MutationOptions } from '../../types'
import { useRequest } from '../../../hooks/useRequest'

import {
  refreshToken,
  RefreshTokenErrorResponse,
  RefreshTokenTokenProps,
  RefreshTokenTokenSuccessResponse
} from './refreshToken'

export const useRefreshTokenAPI = (
  options: MutationOptions<
    RefreshTokenTokenSuccessResponse,
    RefreshTokenErrorResponse,
    RefreshTokenTokenProps
  > = {}
) => {
  const requestContext = useRequest()

  const { isLoading, mutate } = useMutation<
    RefreshTokenTokenSuccessResponse,
    RefreshTokenErrorResponse,
    RefreshTokenTokenProps
  >(refreshToken(requestContext), {
    ...options,
    onSuccess: (...props) => {
      const { data: tokenData } = props[0]

      if (tokenData.token) {
        requestContext.setSession({
          token: tokenData.token,
          userId: tokenData.user.id
        })
        options?.onSuccess?.(...props)
      }
    }
  })

  return {
    isLoading,
    refreshToken: mutate
  }
}
