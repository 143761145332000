import { generatePath } from 'react-router-dom'

import {
  ErrorResponse,
  MutationRequestFunction,
  SuccessResponse
} from '../../types'
import { apiRoutes } from '../../apiRoutes'
import { ErrorType } from '../../commonTypes'

import { PasswordRecoveryVerifyResponse } from './types'

export type PasswordRecoveryVerifyProps = {
  password: string
  token: string
}

export type PasswordRecoveryVerifySuccessResponse =
  SuccessResponse<PasswordRecoveryVerifyResponse>
export type PasswordRecoveryVerifyErrorResponse = ErrorResponse<ErrorType>

export const passwordRecoveryVerify: MutationRequestFunction<
  PasswordRecoveryVerifySuccessResponse,
  PasswordRecoveryVerifyProps
> =
  ({ request }) =>
  async (dto) =>
    request.post(generatePath(apiRoutes.postPasswordRecoveryVerify), dto)
