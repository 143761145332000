import { generatePath } from 'react-router-dom'

import {
  ErrorResponse,
  MutationRequestFunction,
  SuccessResponse
} from '../../types'
import { apiRoutes } from '../../apiRoutes'
import { ErrorType } from '../../commonTypes'

import { EmailVerifyResponse } from './types'

export type EmailVerifyProps = {
  token: string
}

export type EmailVerifySuccessResponse = SuccessResponse<EmailVerifyResponse>
export type EmailVerifyErrorResponse = ErrorResponse<ErrorType>

export const emailVerify: MutationRequestFunction<
  EmailVerifySuccessResponse,
  EmailVerifyProps
> =
  ({ request }) =>
  async (token) =>
    request.post(generatePath(apiRoutes.postEmailVerify), token)
