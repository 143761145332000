import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { useNotification } from '../../../atoms/Notification'
import { useGetCurrentUserAPI } from '../../../api/users/getCurrentUser'
import { usePostEmailVerifyAPI } from '../../../api/auth/postEmailVerify'
import { useRequest } from '../../../hooks/useRequest'
import { routes } from '../../../routes'

export const useVerifyEmailModal = () => {
  const [queryParams] = useSearchParams()
  const requestContext = useRequest()
  const location = useLocation()
  const navigate = useNavigate()

  const { openSuccessNotification, openErrorNotification } = useNotification()

  const { isLoading: getUserLoading, getUser, user } = useGetCurrentUserAPI()

  const [isOpen, setOpen] = useState(false)
  const [description, setDescription] = useState('')

  const onOpen = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const onCancel = useCallback(() => {
    setOpen(false)
    navigate(routes.home)
  }, [setOpen])

  const onOpenHandler = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const { isLoading, emailVerify } = usePostEmailVerifyAPI({
    onSuccess: ({ data }) => {
      openSuccessNotification('Email Verified Successfully')
      requestContext.setSession({
        token: data.token,
        userId: data.data.id
      })

      getUser()
      setDescription('Email Verified Successfully')
      onOpenHandler()
    },
    onError: () => {
      openErrorNotification('Email Verification Failed')
      setDescription('Email Verification Failed')
      onOpenHandler()
    }
  })

  useEffect(() => {
    const token = queryParams.get('token')
    if (
      !user &&
      token &&
      location.pathname === `/${routes.emailVerificaiton}`
    ) {
      emailVerify({ token })
    }
  }, [queryParams.get('token')])

  return {
    verifyEmailModalProps: {
      isOpen,
      setOpen,
      onCancel,
      onOpen,
      isLoading: isLoading || getUserLoading,
      description
    }
  }
}
