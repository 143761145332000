import { generatePath } from 'react-router-dom'

import {
  ErrorResponse,
  MutationRequestFunction,
  SuccessResponse
} from '../../types'
import { apiRoutes } from '../../apiRoutes'
import { ErrorType } from '../../commonTypes'

import { PasswordRecoverySendResponse } from './types'

export type PasswordRecoverySendProps = {
  email: string
}

export type PasswordRecoverySendSuccessResponse =
  SuccessResponse<PasswordRecoverySendResponse>
export type PasswordRecoverySendErrorResponse = ErrorResponse<ErrorType>

export const passwordRecoverySend: MutationRequestFunction<
  PasswordRecoverySendSuccessResponse,
  PasswordRecoverySendProps
> =
  ({ request }) =>
  async (email) =>
    request.post(generatePath(apiRoutes.postPasswordRecoverySend), email)
