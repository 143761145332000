import { ActionModalProps } from '../../../atoms/Modal'

export enum IVerifyPhoneModalFields {
  CODE = 'code'
}

export interface IVerifyPhoneModalFormValues {
  [IVerifyPhoneModalFields.CODE]: string
}

export const initialValues: IVerifyPhoneModalFormValues = {
  [IVerifyPhoneModalFields.CODE]: ''
}

export type VerifyPhoneModalProps = Pick<
  ActionModalProps,
  'isOpen' | 'setOpen'
> & {
  isLoading: boolean
  setOpen: (value: boolean) => void
  handleSubmit: (values: IVerifyPhoneModalFormValues) => void
  initialValues: IVerifyPhoneModalFormValues
}
