import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { useNotification } from '../../../atoms/Notification'
import { useGetCurrentUserAPI } from '../../../api/users/getCurrentUser'
import { routes } from '../../../routes'
import { usePostPasswordRecoveryVerifyAPI } from '../../../api/auth/postPasswordRecoveryVerify'

import {
  IVerifyPasswordRecoveryFields,
  IVerifyPasswordRecoveryFormValues,
  VerifyPasswordRecoveryModalProps
} from './types'

export const useVerifyPasswordRecoveryModal = (): {
  verifyPasswordRecoveryModalProps: VerifyPasswordRecoveryModalProps
} => {
  const [queryParams] = useSearchParams()
  const location = useLocation()
  const navigate = useNavigate()

  const { openSuccessNotification, openErrorNotification } = useNotification()

  const { isLoading: getUserLoading, user } = useGetCurrentUserAPI()

  const [isOpen, setOpen] = useState(false)

  const onOpen = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const onCancel = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const { isLoading, passwordRecoveryVerify } =
    usePostPasswordRecoveryVerifyAPI({
      onSuccess: () => {
        openSuccessNotification('Password Recovered Successfully')

        onCancel()
        navigate(routes.home)
        window.location.reload()
      },
      onError: () => {
        openErrorNotification('Password Recovery Failed')
      }
    })

  useEffect(() => {
    const token = queryParams.get('token')
    if (!user && token && location.pathname === `/${routes.passwordRecovery}`) {
      onOpen()
    }
  }, [queryParams.get('token')])

  const handleSubmit = (userFormValues: IVerifyPasswordRecoveryFormValues) => {
    const passwordRecoveryDTO = {
      password: userFormValues.password,
      token: queryParams.get('token') || ''
    }
    passwordRecoveryVerify(passwordRecoveryDTO)
  }

  const initialValues = useMemo(
    () => ({
      [IVerifyPasswordRecoveryFields.PASSWORD]: ''
    }),
    []
  )

  return {
    verifyPasswordRecoveryModalProps: {
      isOpen,
      setOpen,
      isLoading: isLoading || getUserLoading,
      handleSubmit,
      initialValues
    }
  }
}
