import { TypographyOptions } from '@mui/material/styles/createTypography'
import { CSSProperties } from 'react'

import { colors } from './colors'

// 16px is the default font-size used by browsers.
const pxToRem = (px: number) => `${px / 16}rem`
const buildVariant = (
  fontWeight: number,
  size: number,
  lineHeight: number,
  letterSpacing = 0,
  textDecorationLine = 'none'
): CSSProperties => ({
  fontFamily: '"Open Sans", sans-serif',
  fontWeight,
  fontSize: pxToRem(size),
  lineHeight: Math.round((lineHeight / size) * 1e3) / 1e3,
  letterSpacing: `${Math.round((letterSpacing / size) * 1e5) / 1e5}em`,
  textDecorationLine
})

export const typography = {
  fontFamily: '"Open Sans", sans-serif',
  allVariants: {
    color: colors.neutral.darkest
  },
  displayLarge: buildVariant(400, 57, 64),
  displayMedium: buildVariant(400, 45, 52),
  displaySmall: buildVariant(400, 36, 44),
  displaySmallest: buildVariant(400, 25, 44),
  headlineLarge: buildVariant(400, 32, 40),
  headlineMedium: buildVariant(400, 28, 36),
  headlineSmall: buildVariant(400, 24, 32),
  titleLarge: buildVariant(600, 22, 28),
  titleMedium: buildVariant(600, 16, 24, 0.15),
  titleSmall: buildVariant(600, 14, 20, 0.1),
  titleModal: buildVariant(600, 24, 28),
  labelLarger: buildVariant(700, 16, 22),
  labelLarge: buildVariant(600, 14, 20, 0.1),
  labelMedium: buildVariant(600, 12, 16, 0.15),
  labelSmall: buildVariant(600, 11, 16, 0.5),
  bodyLarge: buildVariant(400, 16, 24, 0.15),
  bodyMedium: buildVariant(400, 14, 20, 0.25),
  bodySmall: buildVariant(400, 12, 16, 0.2),
  linkLarge: buildVariant(400, 16, 24, 0, 'underline'),
  linkMedium: buildVariant(400, 14, 20, 0, 'underline'),
  linkSmall: buildVariant(400, 12, 16, 0.2, 'underline'),
  h1: undefined,
  h2: undefined,
  h3: undefined,
  h4: undefined,
  h5: undefined,
  h6: undefined,
  subtitle1: undefined,
  subtitle2: undefined,
  overline: undefined,
  body1: undefined,
  body2: undefined,
  button: undefined,
  caption: buildVariant(400, 12, 16, 0.2, 'underline')
} as TypographyOptions
