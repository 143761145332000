import { apiRoutes } from '../../apiRoutes'
import { ErrorType } from '../../commonTypes'
import {
  ErrorResponse,
  MutationRequestFunction,
  // QueryRequestFunction,
  SuccessResponse
} from '../../types'

import { RefreshTokenResponse } from './types'

export type RefreshTokenTokenProps = {
  sessionToken: string
}
export type RefreshTokenTokenSuccessResponse =
  SuccessResponse<RefreshTokenResponse>
export type RefreshTokenErrorResponse = ErrorResponse<ErrorType>

export const refreshToken: MutationRequestFunction<
  RefreshTokenTokenSuccessResponse,
  RefreshTokenTokenProps
> =
  ({ request }) =>
  async (sessionToken) =>
    request.post(apiRoutes.refreshToken, sessionToken)
