import { generatePath } from 'react-router-dom'

import {
  ErrorResponse,
  MutationRequestFunction,
  SuccessResponse
} from '../../types'
import { apiRoutes } from '../../apiRoutes'
import { ErrorType } from '../../commonTypes'

import { EmailSendResponse } from './types'

export type EmailSendProps = {
  email: string
}

export type EmailSendSuccessResponse = SuccessResponse<EmailSendResponse>
export type EmailSendErrorResponse = ErrorResponse<ErrorType>

export const emailSend: MutationRequestFunction<
  EmailSendSuccessResponse,
  EmailSendProps
> =
  ({ request }) =>
  async (email) =>
    request.post(generatePath(apiRoutes.postEmailSend), email)
