import { useQuery } from 'react-query'
import { useMemo } from 'react'

import { useRequest } from '../../../hooks/useRequest'
import { QUERY_CACHE_KEYS } from '../../cacheKeys'
import { QueryOptions } from '../../types'
import { localStorageKeys } from '../../../constants/localStorageKeys'

import {
  GetUserErrorResponse,
  getUser,
  GetUserSuccessResponse
} from './getCurrentUser'

export const useGetCurrentUserAPI = (
  options: QueryOptions<GetUserSuccessResponse, GetUserErrorResponse> = {}
) => {
  const requestContext = useRequest()

  const userId = useMemo(() => {
    return localStorage.getItem(localStorageKeys.IFCsession)
      ? JSON.parse(localStorage.getItem(localStorageKeys.IFCsession) || '')
          ?.userId
      : null
  }, [])

  const { isLoading, isRefetching, data, refetch, error } = useQuery<
    GetUserSuccessResponse,
    GetUserErrorResponse
  >(QUERY_CACHE_KEYS.user(userId), getUser(requestContext), {
    enabled: true,
    ...options
  })

  return {
    isLoading: isLoading || isRefetching,
    getUser: refetch,
    user: data?.data?.data,
    error
  }
}
