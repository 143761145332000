import { FC } from 'react'
import { Box } from '@mui/material'

import { PageLayout, PageLayoutProps } from '../../../layout/PageLayout'
import { EventInfoWidget } from '../../../features/widgets/EventInfoWidget'
import { EventCardsListWidget } from '../../../features/widgets/EventCardsListWidget'
import { HomePageFooter } from '../../../features/others/HomePageFooter'

const pageLayoutProps = {
  stack: {
    spacing: 0
  }
} as PageLayoutProps['slotProps']

export const HomePage: FC = () => {
  return (
    <PageLayout slotProps={pageLayoutProps}>
      <Box
        sx={
          /* { margin: 0, padding: 0 } */ {
            margin: 0,
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            height: '100vh'
          }
        }
      >
        <img
          style={{
            position: 'absolute',
            top: '0',
            // width: '100%',
            width: '80vw',
            maxWidth: '100vw',
            overflowX: 'hidden'
          }}
          src='/backgroundTitle.png'
          alt='backgroundTitle'
        />
        <img
          style={{
            width: '100vw',
            // width: '80vw',
            minWidth: '1004px',
            // maxHeight: '815px',
            maxHeight: '100vh',
            minHeight: '770px'
          }}
          src='/homePageBackground.png'
          alt='background'
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '160px',
          left: '0',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <EventInfoWidget />
      </Box>
      {/* <Box
        sx={{
          background:
            'linear-gradient(180deg, rgba(13, 13, 13, 0) 0%, #0D0D0D 100%)',
          height: '100px',
          position: 'absolute',
          top: 'vh',
          width: '100%'
        }}
      /> */}
      <Box
        sx={{
          position: 'relative',
          zIndex: 1
        }}
      >
        <EventCardsListWidget />
        <HomePageFooter />
      </Box>
    </PageLayout>
  )
}
