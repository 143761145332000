import { FC, useMemo } from 'react'
import { Stack } from '@mui/material'

import { ActionModal } from '../../../atoms/Modal'
import { MainTypography } from '../../../atoms/Typography'
import { FilledButton } from '../../../atoms/FilledButton'
import { LoadableContainer } from '../../../layout/LoadableContainer'

import { PaymentResultModalProps } from './types'

export const PaymentResultModal: FC<PaymentResultModalProps> = ({
  isOpen,
  isLoading,
  onCancel,
  isSuccessfulPayment
}) => {
  const title = useMemo(() => {
    return isSuccessfulPayment ? 'Payment Successful' : 'Payment Failed'
  }, [isSuccessfulPayment])

  const description = useMemo(() => {
    return isSuccessfulPayment
      ? 'Your payment was accepted.'
      : 'Your payment was unsuccessful. Please try again.'
  }, [isSuccessfulPayment])

  const handleClose = () => {
    onCancel()
  }

  return (
    <ActionModal isOpen={isOpen} setOpen={handleClose}>
      <LoadableContainer loading={isLoading}>
        <Stack
          sx={{
            width: '100%',
            marginBottom: 3,
            textAlign: 'center'
          }}
        >
          <MainTypography
            sx={{
              color: 'black'
            }}
          >
            {title}
          </MainTypography>
        </Stack>
        <Stack rowGap={3}>
          <MainTypography
            sx={{
              color: 'black',
              fontSize: '18px',
              textAlign: 'center'
            }}
          >
            {description}
          </MainTypography>

          <Stack
            rowGap={2}
            sx={{
              width: '70%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: 'auto'
            }}
          >
            <FilledButton onClick={handleClose}>Close</FilledButton>
          </Stack>
        </Stack>
      </LoadableContainer>
    </ActionModal>
  )
}
