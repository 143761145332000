import { MutationOptions, useMutation } from 'react-query'

import { useRequest } from '../../../hooks/useRequest'

import {
  emailSend,
  EmailSendErrorResponse,
  EmailSendProps,
  EmailSendSuccessResponse
} from './emailSend'

export const usePostEmailSendAPI = (
  options: MutationOptions<
    EmailSendSuccessResponse,
    EmailSendErrorResponse,
    EmailSendProps
  > = {}
) => {
  const requestContext = useRequest()

  const { isLoading, mutate } = useMutation<
    EmailSendSuccessResponse,
    EmailSendErrorResponse,
    EmailSendProps
  >(emailSend(requestContext), options)

  return {
    isLoading,
    emailSend: mutate
  }
}
