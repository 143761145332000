import { FC } from 'react'
import { Button, ButtonProps, buttonClasses, styled } from '@mui/material'

export type OutlinedButtonProps = Omit<ButtonProps, 'variant'>

export const StyledButton = styled(Button)(({ theme }) => ({
  cursor: 'pointer',
  [`&.${buttonClasses.root}`]: {
    color: theme.palette.primary.main,
    border: `0px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
    paddingInline: theme.spacing(3),
    textTransform: 'none',
    height: theme.spacing(6),
    ...theme.typography.labelLarge,
    '&:hover': {
      // backgroundColor: theme.palette.primary.lighter
    },
    '&:disabled': {
      color: theme.palette.neutral.medium
      // border: `1px solid ${theme.palette.neutral.medium}`
    },
    '&:focus': {
      color: theme.palette.primary.dark,
      border: `1px solid ${theme.palette.primary.dark}`
    }
  }
}))

export const OutlinedButton: FC<OutlinedButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <StyledButton variant='outlined' {...props}>
      {children}
    </StyledButton>
  )
}
