import { FC } from 'react'
import {
  Button,
  ButtonProps,
  SxProps,
  Theme,
  buttonClasses
} from '@mui/material'

export type TextButtonProps = Omit<ButtonProps, 'variant'>

const DEFAULT_BUTTON_SIZE = 48

const textButtonStyles: SxProps<Theme> = ({ palette, typography }) => ({
  height: DEFAULT_BUTTON_SIZE,
  bgcolor: 'transparent',
  typography: typography.labelLarge,
  textTransform: 'none',
  '&:hover': {
    bgcolor: palette.primary.lighter,
    color: palette.primary.main
  },
  [`&.${buttonClasses.disabled}`]: {
    color: palette.neutral.main
  },
  [`&.${buttonClasses.focusVisible}`]: {
    bgcolor: palette.primary.lighter,
    color: palette.primary.dark
  }
})

export const TextButton: FC<TextButtonProps> = ({ children, ...props }) => {
  return (
    <Button sx={textButtonStyles} {...props} variant='text'>
      {children}
    </Button>
  )
}
