import { FC, PropsWithChildren, MouseEvent } from 'react'
import { BoxProps, Modal as MUIModal, styled, Grid } from '@mui/material'

export type ModalProps = {
  isOpen: boolean
  onClose?: (reason: 'backdropClick' | 'escapeKeyDown') => void
  sx?: BoxProps['sx']
} & PropsWithChildren

const StyledModal = styled(MUIModal)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

export const Modal: FC<ModalProps> = (props) => {
  const { isOpen, children, onClose, sx = {} } = props

  return (
    <StyledModal
      open={isOpen}
      onClose={(_, reason) => {
        if (onClose) {
          onClose(reason)
        }
      }}
      onClick={(event: MouseEvent<HTMLDivElement>) => event.stopPropagation()}
    >
      <Grid container sx={sx}>
        {children}
      </Grid>
    </StyledModal>
  )
}
