import { MutationOptions, useMutation } from 'react-query'

import { useRequest } from '../../../hooks/useRequest'

import {
  passwordRecoverySend,
  PasswordRecoverySendErrorResponse,
  PasswordRecoverySendProps,
  PasswordRecoverySendSuccessResponse
} from './passwordRecoverySend'

export const usePostPasswordRecoverySendAPI = (
  options: MutationOptions<
    PasswordRecoverySendSuccessResponse,
    PasswordRecoverySendErrorResponse,
    PasswordRecoverySendProps
  > = {}
) => {
  const requestContext = useRequest()

  const { isLoading, mutate } = useMutation<
    PasswordRecoverySendSuccessResponse,
    PasswordRecoverySendErrorResponse,
    PasswordRecoverySendProps
  >(passwordRecoverySend(requestContext), options)

  return {
    isLoading,
    passwordRecoverySend: mutate
  }
}
