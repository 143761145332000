import {
  ElementType,
  FC,
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
  useMemo
} from 'react'
import { Grid, GridProps, SxProps, Theme, Typography } from '@mui/material'
import { Show } from 'react-extend-jsx'

import { CloseButton } from '../CloseButton'
import { FilledButton, FilledButtonProps } from '../FilledButton'
import { TextButton } from '../TextButton'
import { noop } from '../../utils/noop'

import { Modal } from './Modal'

export type ActionModalProps = PropsWithChildren<{
  isOpen: boolean
  title?: string
  buttonsPosition?: GridProps['justifyContent']
  buttonsTopPadding?: number
  sx?: SxProps<Theme>
  submit?: {
    text?: string
    disabled?: boolean
    callback?: MouseEventHandler<Element>
    closeModal?: boolean
    component?: ElementType<FilledButtonProps>
  }
  setOpen: (status: boolean) => void
  cancel?: {
    text?: string
    callback?: MouseEventHandler<Element>
    closeModal?: boolean
    disabled?: boolean
  }
  exit?: {
    callback?: MouseEventHandler<Element>
    disabled?: boolean
    closeModal?: boolean
  }
  onClose?: (reason: 'backdropClick' | 'escapeKeyDown') => void
}>

const defaultStyle: SxProps<Theme> = {
  width: 'auto',
  minWidth: '458px',
  maxWidth: '660px',
  maxHeight: '90vh',
  overflowY: 'auto',
  backgroundColor: 'common.white',
  borderRadius: 2
}

export const ActionModal: FC<ActionModalProps> = (props) => {
  const {
    isOpen,
    title,
    buttonsPosition = 'flex-end',
    buttonsTopPadding = 5,
    sx = {},
    children,
    cancel = {},
    submit = {},
    exit = {},
    setOpen,
    onClose
  } = props

  const {
    text: submitText,
    callback: submitCallback = noop,
    disabled: submitDisabled = false,
    closeModal: submitCloseModal = true,
    component: SubmitComponent = FilledButton
  } = submit
  const {
    text: cancelText,
    callback: cancelCallback = noop,
    closeModal: cancelCloseModal = true,
    disabled: cancelDisabled = false
  } = cancel

  const {
    callback: exitCallback,
    disabled: exitDisabled,
    closeModal: exitCloseModal = true
  } = exit

  const onSubmitHandler = useCallback<MouseEventHandler>(
    async (e) => {
      e.stopPropagation()
      e.preventDefault()

      await submitCallback(e)

      if (submitCloseModal) {
        //   setOpen(false)
      }
    },
    [submitCallback, setOpen]
  )

  const onCancelHandler = useCallback<MouseEventHandler>(
    (e) => {
      e.stopPropagation()
      e.preventDefault()
      cancelCallback(e)

      if (cancelCloseModal) {
        setOpen(false)
      }
    },
    [cancelCallback, setOpen]
  )

  const onExitHandler = useCallback<MouseEventHandler>(
    (e) => {
      e.stopPropagation()
      e.preventDefault()
      if (exitCallback) {
        exitCallback(e)
      } else {
        onCancelHandler(e)
      }

      if (exitCloseModal) {
        setOpen(false)
      }
    },
    [exitCallback, setOpen, onCancelHandler]
  )

  const style = useMemo(() => {
    return {
      ...defaultStyle,
      ...sx
    }
  }, [sx])

  return (
    <Modal isOpen={isOpen} sx={style} onClose={onClose}>
      <Grid container p={4}>
        <Grid
          item
          container
          flexDirection={title ? 'row' : 'row-reverse'}
          justifyContent='space-between'
          alignItems='center'
        >
          <Show when={!!title}>
            <Grid item pl={4} xs={10} md={11}>
              <Typography variant='titleModal'>{title}</Typography>
            </Grid>
          </Show>
          <Grid item>
            <CloseButton disabled={exitDisabled} onClick={onExitHandler} />
          </Grid>
        </Grid>
        <Grid item container px={4}>
          <Grid item sx={{ width: '100%' }}>
            {children}
          </Grid>
          <Show when={!!cancelText || !!submitText}>
            <Grid
              container
              item
              justifyContent={buttonsPosition}
              pt={buttonsTopPadding}
            >
              <Show when={!!cancelText}>
                <Grid item>
                  <TextButton
                    onClick={onCancelHandler}
                    disabled={cancelDisabled}
                    style={{ width: 94, minWidth: 94 }}
                  >
                    {cancelText}
                  </TextButton>
                </Grid>
              </Show>
              <Show when={!!submitText}>
                <Grid item ml={buttonsPosition !== 'center' ? 2 : 0}>
                  <SubmitComponent
                    onClick={onSubmitHandler}
                    disabled={submitDisabled}
                    sx={{ width: 160, minWidth: 160 }}
                  >
                    {submitText}
                  </SubmitComponent>
                </Grid>
              </Show>
            </Grid>
          </Show>
        </Grid>
      </Grid>
    </Modal>
  )
}
