type ID = string | number

export const QUERY_CACHE_KEYS = {
  /* User */
  user: (id: ID) => `logged-${id}`,

  /* Event */
  event: 'event',

  /* Security */
  refreshToken: 'refreshToken'
}
