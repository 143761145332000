import { forwardRef, useCallback } from 'react'
import { useSnackbar, SnackbarContent, CustomContentProps } from 'notistack'
import { Alert, AlertTitle, Typography, styled } from '@mui/material'
import { Show } from 'react-extend-jsx'

interface NotificationProps extends CustomContentProps {
  title: string
}

const StyledAlert = styled(Alert)(() => ({
  width: 400
}))

const StyledAlertTitle = styled(AlertTitle)(({ theme }) => ({
  ...theme.typography.titleMedium,
  color: theme.palette.neutral.lightest
}))

export const Notification = forwardRef<HTMLDivElement, NotificationProps>(
  (props, ref) => {
    const { id, message, title, className, style, variant } = props
    const { closeSnackbar } = useSnackbar()

    const handleDismiss = useCallback(() => {
      closeSnackbar(id)
    }, [id, closeSnackbar])

    const severity = variant === 'error' ? 'error' : 'success'

    return (
      <SnackbarContent
        ref={ref}
        role='alert'
        className={className}
        style={style}
      >
        <StyledAlert
          onClose={handleDismiss}
          variant='filled'
          severity={severity}
          sx={{
            alignItems: !message ? 'center' : 'initial'
          }}
        >
          <Show
            when={message}
            fallback={
              <Typography variant='titleMedium' color='neutral.lightest'>
                {title}
              </Typography>
            }
          >
            <>
              <StyledAlertTitle>{title}</StyledAlertTitle>
              <Typography variant='bodyMedium' color='neutral.lightest'>
                {message}
              </Typography>
            </>
          </Show>
        </StyledAlert>
      </SnackbarContent>
    )
  }
)
