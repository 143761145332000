import {
  FC,
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useMemo,
  useState
} from 'react'
import {
  IconButton,
  IconButtonProps,
  Menu,
  MenuItem,
  Typography,
  paperClasses,
  styled
} from '@mui/material'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import { For, Show } from 'react-extend-jsx'

import { OutlinedButton } from '../OutlinedButton'
import { theme } from '../../styles/theme'
import { MainTypography } from '../Typography'

interface Action {
  label: string
  icon?: JSX.Element
  onClick?: MouseEventHandler
}

export interface ActionMenuProps {
  label?: string
  Icon?: JSX.Element
  iconButtonSx?: IconButtonProps['sx']
  actions: Action[]
}

const StyledMenu = styled(Menu)(() => ({
  marginTop: '4px',
  [`.${paperClasses.root}`]: {
    boxShadow: theme.idionShadows.menu
  }
}))

const StyledMenuItem = styled(MenuItem)(() => ({
  minWidth: 168,
  padding: '12px 16px'
}))

export const ActionMenu: FC<ActionMenuProps> = ({
  Icon,
  actions,
  label,
  iconButtonSx
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (!actions.length) return

      event.stopPropagation()
      setAnchorEl(event.currentTarget)
    },
    [setAnchorEl, actions]
  )

  const handleClose = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      setAnchorEl(null)
    },
    [setAnchorEl]
  )

  const mappedActions = useMemo(() => {
    return actions.map((action) => {
      return {
        ...action,
        onClick: (e: MouseEvent) => {
          action.onClick?.(e)
          handleClose(e)
        }
      }
    })
  }, [actions, handleClose])

  const renderMenuItem = (action: Action) => (
    <StyledMenuItem onClick={action.onClick}>
      {action.icon && action.icon}
      <Typography
        variant='bodyMedium'
        marginLeft='4px'
        color={theme.palette.neutral.lightest}
        textAlign='center'
        fontSize={18}
      >
        {action.label}
      </Typography>
    </StyledMenuItem>
  )

  return (
    <>
      <Show
        when={!!label}
        fallback={
          <IconButton
            aria-controls='action-menu'
            aria-haspopup='true'
            onClick={handleClick}
            sx={{ p: 0, ...iconButtonSx }}
          >
            {Icon}
          </IconButton>
        }
      >
        <OutlinedButton
          aria-controls='action-menu'
          aria-haspopup='true'
          onClick={handleClick}
          startIcon={Icon}
          sx={{ p: 0, ...iconButtonSx }}
          endIcon={
            actions.length > 0 && (
              <KeyboardArrowDownRoundedIcon
                htmlColor={theme.palette.neutral.lightest}
              />
            )
          }
        >
          <MainTypography>{label}</MainTypography>
        </OutlinedButton>
      </Show>

      <StyledMenu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          disablePadding: true
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              backgroundColor: '#000000BF'
              // borderRadius: '12px'
            }
          }
        }}
      >
        <For of={mappedActions}>{renderMenuItem}</For>
      </StyledMenu>
    </>
  )
}
